import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import S from './style'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useParams } from 'react-router-dom';
import data from '../../services/data';
import dataen from '../../services/dataen';

import Slider from 'react-slick';
import { LocaleContext } from '../../locale-context';
import TranslateStatic from '../../locales/TranslateStatic';
import { Banner } from '../Category/banner';
export function Product(props) {
  let { subcatid, catid, name } = useParams();
  const value = useContext(LocaleContext);
  const [result, setResult] = useState(false);
  const [current, setCurrent] = useState(false);
  useEffect(() => {
    if (value === "en") {
      let filterdata = dataen.find(element => element.category.id == catid)
      let filterdata2 = filterdata.category.subcategories.find(element => element.id == subcatid)
      console.log(filterdata2)
      setResult(filterdata2)

    } else if (value === "tr") {
      let data2 = data.find(element => element.category.id == catid)
      let data22 = data2.category.subcategories.find(element => element.id == subcatid)
      console.log(data22)
      setResult(data22)
    }

  }
    , [catid, subcatid, value])
  function getSliderSettings() {
    return {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    }
  }

  function handleClickImage(e, image) {
    e && e.preventDefault();

    setCurrent(image)
  }

  function handleCloseModal(e) {
    e && e.preventDefault();

    setCurrent('')
  }
  // const settings = getSliderSettings();
  const settings = {
    customPaging: function (i) {
      return (
        <a>
          <img src={result.imgsrc[i]} />
        </a>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb custom-paging",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    adaptiveHeight: true
  };
  return (
    <S.Wrapper>
      <Banner />
      <S.Section>
        <div className="item-wrapper">
          {result && result.detail}
          <div className="item-image">
            {result && <Slider {...settings}>
              {result.imgsrc.map(image => (
                <div>
                  <img src={image} onClick={e => handleClickImage(e, image)} />
                </div>
              ))}
            </Slider>

            }
          </div>



        </div>

        {/* {current &&
          <Lightbox
            mainSrc={current}
            onCloseRequest={handleCloseModal}
            nextSrc={result.imgsrc[(current + 1) % result.imgsrc.length]}
            prevSrc={result.imgsrc[(current + result.imgsrc.length - 1) % result.imgsrc.length]}
            onMovePrevRequest={() =>
              setCurrent((current + result.imgsrc.length - 1) % result.imgsrc.length)
            }
            onMoveNextRequest={() =>
              setCurrent((current + 1) % result.imgsrc.length)
            }
          />
        } */}
      </S.Section>
    </S.Wrapper>
  );
}
