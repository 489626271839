
import { Link } from 'react-router-dom';
import S from './style'

import TranslateStatic from '../../locales/TranslateStatic';
import YumurtlamaImg from '../../assets/endoskopik.jpeg'
export function AboutUs() {
  return (
    <S.Wrapper>
      <S.Top>
        {/* <S.Header>
          <TranslateStatic string="aboutUsH2" />
        </S.Header> */}
        <S.Span>
          <Link to="./"><TranslateStatic string="home" /></Link> > <Link><TranslateStatic string="aboutus" /></Link>
        </S.Span>
      </S.Top>
      {/* <S.Sertifika>
        <Certification />
      </S.Sertifika> */}
      <S.Section>
        <div className='banner'>
          <div className='baslik'>
            <h1>
              <TranslateStatic string="aboutus" />
            </h1>
          </div>
        </div>
        <div className='content'>
          
          <div className='texts'>
            <p><TranslateStatic string="HakkımızP1" /></p>

            <p><TranslateStatic string="HakkımızP2" /></p>
            <p><TranslateStatic string="HakkımızP3" /></p>
            <p><TranslateStatic string="HakkımızP4" /></p>
          </div>
          {/* <h3>360° Kliniğimiz</h3> */}
          <div className='mapContainer'>
            <iframe src="https://www.google.com/maps/embed?pb=!4v1715531385136!6m8!1m7!1sCAoSLEFGMVFpcFAxbFRTZE51bEIzcnFwNE9wcjF5M1JsNVFfMHp1YWhwRHNFVjl3!2m2!1d36.8598667!2d30.77972999999999!3f280!4f10!5f0.7820865974627469" allowfullscreen="" loading="eager" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <iframe src="https://www.google.com/maps/embed?pb=!4v1715531749611!6m8!1m7!1sCAoSLEFGMVFpcE5nSHdINHFIM2pabUp0cHJ3Tm12YWZaNHZzNDU1VUp5UFYzNU8y!2m2!1d36.86025!2d30.7796167!3f40!4f20!5f0.7820865974627469" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <iframe src="https://www.google.com/maps/embed?pb=!4v1715531788864!6m8!1m7!1sCAoSLEFGMVFpcE45dm1nYXRqbDg3bXFXU2VQNmlXVC1vWXFZSzRBMU1ncFV1WnAt!2m2!1d36.8600833!2d30.7798667!3f220!4f0!5f0.7820865974627469" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <iframe src="https://www.google.com/maps/embed?pb=!4v1715532861701!6m8!1m7!1sCAoSLEFGMVFpcE9PbUpBOGNJMmdxWjI0bGRpMmJRZzVBal9XdDFONHlJNnF0S0N6!2m2!1d36.8602217!2d30.7798133!3f300!4f20!5f0.7820865974627469" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <iframe src="https://www.google.com/maps/embed?pb=!4v1715532899110!6m8!1m7!1sCAoSLEFGMVFpcE9GOTJ0bXVkVGFBUHBkb2dfbGprRUlHYkVfcHRJblA2ZlZ0a3hs!2m2!1d36.8602467!2d30.7797967!3f260!4f0!5f0.7820865974627469" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <iframe src="https://www.google.com/maps/embed?pb=!4v1715532931214!6m8!1m7!1sCAoSLEFGMVFpcFBMdzdqOGUzYVdNbm0yb3JNOW5FSXg3SlRneTVqUDUxc05YTU9j!2m2!1d36.8602867!2d30.7798667!3f280!4f0!5f0.7820865974627469" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <iframe src="https://www.google.com/maps/embed?pb=!4v1715532960568!6m8!1m7!1sCAoSLEFGMVFpcFBZdlhUWF95Z3dOVXQ3NTV5RHh6dDNWVnpzekZEc1VpTG1JUmFI!2m2!1d36.859995!2d30.7795083!3f0!4f20!5f0.7820865974627469" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <iframe src="https://www.google.com/maps/embed?pb=!4v1715532989514!6m8!1m7!1sCAoSLEFGMVFpcE1sd0wxZzd6Zkh3c1ktcnU5d05LUGFYcGpzbnh4dnRTemxJTU5Y!2m2!1d36.8603883!2d30.779675!3f240!4f10!5f0.7820865974627469" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <iframe src="https://www.google.com/maps/embed?pb=!4v1715533016514!6m8!1m7!1sCAoSLEFGMVFpcFAzTzZxRmxULVRVOE9TS0VNS0o4R3kzR1NMTTRkWHFKb0JTUXJM!2m2!1d36.86007!2d30.779445!3f254.8330480678282!4f-11.03841865520424!5f0.7820865974627469" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <iframe src="https://www.google.com/maps/embed?pb=!4v1715533051289!6m8!1m7!1sCAoSLEFGMVFpcFBxOXFGbXR6NGlUemEtWGlLQjl3MkR5Z1Vob21RQjBwakNBYUF3!2m2!1d36.8599583!2d30.77949169999999!3f280!4f0!5f0.7820865974627469" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <iframe src="https://www.google.com/maps/embed?pb=!4v1715533082464!6m8!1m7!1sCAoSLEFGMVFpcE5ZSVJLM0wwVUVrZWVLOVFlLUFWZ25iNWtmbHk3LTZELVBUMVRr!2m2!1d36.8599883!2d30.779305!3f260!4f0!5f0.7820865974627469" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <iframe src="https://www.google.com/maps/embed?pb=!4v1715533106201!6m8!1m7!1sCAoSLEFGMVFpcE4wTUFzVllPV19LUkpZUG5EVnQ5NzgtVTZGUWR1dkFqbERNVFpZ!2m2!1d36.860195!2d30.7795467!3f120!4f0!5f0.7820865974627469" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <iframe src="https://www.google.com/maps/embed?pb=!4v1715533136782!6m8!1m7!1sCAoSLEFGMVFpcE9zaGNtMXh0bFNSZlhDbkNnRHhzMmxESnlDbEttWHpwRXJKTGct!2m2!1d36.86002!2d30.7799467!3f3.850000699735176!4f-18.175368382994435!5f0.7820865974627469" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <iframe src="https://www.google.com/maps/embed?pb=!4v1715533165514!6m8!1m7!1sCAoSLEFGMVFpcE5RNmFxZU1hUTcxT3VOWjRRZFN2cEtqUlFfTV9zVTBDcVVDUWxi!2m2!1d36.8601617!2d30.7794633!3f340!4f10!5f0.7820865974627469" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <iframe src="https://www.google.com/maps/embed?pb=!4v1715533181635!6m8!1m7!1sCAoSLEFGMVFpcE1aN1RuWkV5VkJ4NXV0LTBybXJVSEdEZXhqUEQtUXBxNXRabjdt!2m2!1d36.8602667!2d30.7794783!3f120!4f0!5f0.7820865974627469" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <iframe src="https://www.google.com/maps/embed?pb=!4v1715533199996!6m8!1m7!1sCAoSLEFGMVFpcE9PZVFmVnFhRnpVOVFHU0dDdVdVbC15SmcyejlhZ0dnTXRPZE5k!2m2!1d36.8600633!2d30.7794167!3f280!4f20!5f0.7820865974627469" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <iframe src="https://www.google.com/maps/embed?pb=!4v1715533228209!6m8!1m7!1sCAoSLEFGMVFpcE04UnBXbTd5WVhVbDVYai14UGREYkpPMi1EZmt2Vnd1Z215a21i!2m2!1d36.859845!2d30.7795217!3f320!4f0!5f0.7820865974627469" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <iframe src="https://www.google.com/maps/embed?pb=!4v1715533255321!6m8!1m7!1sCAoSLEFGMVFpcE1fYjNGN0p0bUJzVnBMQjJ4Tk9DaHFUMUt4NkJUSUxuWDF2WjM3!2m2!1d36.859895!2d30.7795667!3f115.62703041568426!4f-23.486536370419657!5f0.7820865974627469" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <iframe src="https://www.google.com/maps/embed?pb=!4v1715533274584!6m8!1m7!1sCAoSLEFGMVFpcE1oMThnbjQ2MEZtZHZQOGNmaDhocHAxc1lrZUFTeVkwSkxtOFQx!2m2!1d36.8602083!2d30.7796367!3f295.75800048172005!4f3.0128510912502975!5f0.7820865974627469" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
           
            <iframe src="https://www.google.com/maps/embed?pb=!4v1715533305406!6m8!1m7!1sCAoSLEFGMVFpcFAzQmQ4SmlOMWlpNzlnOFFuZTN0UGpQRFFOd3U3OHpwV3hQcHVE!2m2!1d36.8598717!2d30.77975!3f200!4f10!5f0.7820865974627469" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <iframe src="https://www.google.com/maps/embed?pb=!4v1715533318792!6m8!1m7!1suRAuzwQAjVkIsXqqOsQOyA!2m2!1d36.86023267545747!2d30.77979053927509!3f293.56342!4f0!5f0.7820865974627469" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <iframe src="https://www.google.com/maps/embed?pb=!4v1715533330271!6m8!1m7!1sCAoSLEFGMVFpcE9GRGhaaldmbUpSQWs0SUdET01RSURsR3NLYkhJZTR0ZGZvRFdH!2m2!1d36.86053!2d30.7798217!3f220!4f10!5f0.7820865974627469" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>


      </S.Section>
    </S.Wrapper >
  );
}
