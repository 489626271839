
import { Link } from 'react-router-dom';
import S from './style'
import TranslateStatic from '../../locales/TranslateStatic';
import YumurtlamaImg from '../../assets/dondurarak.jpeg'
import { Table } from './table';
export function EkTedaviler() {
  return (
    <S.Wrapper>
      <S.Top>
        {/* <S.Header>
          <TranslateStatic string="aboutUsH2" />
        </S.Header> */}
        <S.Span>
          <Link to="./"><TranslateStatic string="home" /></Link> > <Link><TranslateStatic string="ektedaviler" /></Link>
        </S.Span>
      </S.Top>
      {/* <S.Sertifika>
        <Certification />
      </S.Sertifika> */}
      <S.Section>
        <div className='banner'>
          <div className='baslik'>
            <h1>
              <TranslateStatic string="ektedaviler" />
            </h1>
          </div>
          <div className='imgwrap'>

            <img className='foregroundImg' src={YumurtlamaImg} />
            <img className="backgroundImg" src={YumurtlamaImg} />
          </div>



        </div>
        <h3>
        <TranslateStatic string="EktedavilerH3" />
        </h3>
        <p>
        <TranslateStatic string="EkTedavilerP1" />
        </p>
        <h4> <TranslateStatic string="EkTedavilerH4" /></h4>
        <span><TranslateStatic string="EkTedavilerSpan" /></span>
        <h2><TranslateStatic string="EkTedavilerH2" /></h2>
        <Table/>


      </S.Section>
    </S.Wrapper >
  );
}
