import styled from "styled-components";

const S = {
    carousel: styled.div`
    display: flex;
    overflow: hidden;
    height: 100%;
    `,
    carouselPrev: styled.div`
    position: absolute;
    cursor: pointer;
    z-index: 1;
    height: 100%;
    width: 100px;
    left: 0;
    @media (min-width: 768px) {
        &::before {
          position: relative;

          transform: scale(.1) rotate(270deg);
          top: 50%;
          left: 20px;
          filter: brightness(10);
          display: inline-block;
          width: 10px;
          height: 10px;
        // transform: rotate(270deg);
        }
    }
    `,
    carouselNext: styled.div`
    position: absolute;
    cursor: pointer;
    z-index: 1;
    height: 100%;
    width: 100px;
    right: 0;
    @media (min-width: 768px) {
        &::before {
            position: relative;
            
          transform: scale(.1) rotate(90deg);
            top: 46%;
            right: -70px;
            filter: brightness(10);
            display: inline-block;
            width: 10px;
          height: 10px;
           
        }
    }
    `,
    carouselItem: styled.div`
    position: relative;
    display: inline-block;
    flex: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    overflow: hidden;
  
    transition: flex 1s ease;
    &::before{
        background:linear-gradient(0deg, #0003 0%, #0009 100%);
    }
    video{
      position: absolute;
      width: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); 
        filter: brightness(0.6);
      @media (max-width:648px){
          // height:100vh;
          width: auto;
      }  
    }
    // img {
    //     position: absolute;
    //     width: 100vw;
    //     // min-height: 100%;
    //     top: 50%;
    //     left: 50%;
    //     transform: translate(-50%, -50%); 
    //     filter: brightness(0.6);
        
    //   }
      
      &.current {
        flex: 1;
      }
    `,
    carouselHeading: styled.div`
    display: flex;
    flex-direction:row;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    padding: 10px 140px;
    transition:0.5;
    justify-content: space-between;
    @media (max-width:648px){
      flex-direction:column;
      padding: 10px 50px;
    }  
    .left{
      width:50%;
      display:flex;
      flex-direction:column;
      h2{
        padding: 0px;
        color: #01aecb;
        font-size:2em;
        text-align:left;
        width:100%;
      }
      p {
        padding: 0px;
        color: #ECF0F1;
        font-size:1em;
        text-align:left;
        width:100%;
        // height:57px;
        // display: -webkit-box; 
        // -webkit-box-orient: vertical; 
        // -webkit-line-clamp: 4; 
        // overflow: hidden; 
        // text-overflow: ellipsis; 
        // transition:300ms;
        
      }
      .pAuto {
        display: flex; 
        height:auto;
        overflow: visible; 
        transition:300ms;
      }
      &.show{
          opacity:0;
          transition: opacity 0.5s;
      }
      @media (max-width:648px){
        // height:100vh;
        width: 100%;
      }  
    }
    .right{
      color:white;
      width:150px;
      height:150px;
      padding:60px;
      display:flex;
      flex-direction:column;
      clip-path: polygon(17% 0, 100% 0, 100% 100%, 0 100%, 0 17%);
      background: rgba(255, 255, 255, 0.2);
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      @media (max-width:648px){
      margin:20px;
      }  
    }
    `,
    RandevuWrap: styled.div`
    display:flex;
    flex-direction:row;
    justify-content: flex-start;
    color:white;
    align-items: center;
    a{
      border:0px;
      background:white;
      color:#00A9C1;
      padding:15px 20px;
      border-radius:4px;
      margin-right:10px;
      font-weight:600;
      font-size:16px;
      text-decoration:none;
      &:last-child{
        background:none;
        color:white;
      }
      &:hover{
        background-color:#00A9C1;
        color:white;
        .tele{
          filter: brightness(500%);
        }
      }
      .tele{
        width:16px;
        vertical-align:text-top;
        padding-left:10px;
      }
    }
    `
}

export default S