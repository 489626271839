
import { Link } from 'react-router-dom';
import S from './style'


import TranslateStatic from '../../locales/TranslateStatic';
import Accordion from './accordion'
export function Reference() {
  return (
    <S.Wrapper>
  
      <TranslateStatic string="SSSBaslik"/>
      <S.Section>
       <Accordion/>
      </S.Section>
    </S.Wrapper>
  );
}
