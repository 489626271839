import React, { useState } from 'react'
//import the css here
import S from './style'
import Whatsappicon from '../../assets/whatsappicon.png'
import InstaColor from '../../assets/instagramColor.png'
import Telephone from '../../assets/telephone.png'
import Close from '../../assets/close.png'
import Minik from './miniSlider'
import TranslateStatic from '../../locales/TranslateStatic'
import { LocaleContext } from '../../locale-context';
export default function (props) {
    const [chatopen, setChatopen] = useState(false)
    let hide = {
        display: 'none',
    }
    let show = {
        display: 'block'
    }
    let textRef = React.createRef()
    // const { messages } = props

    const toggle = e => {
        setChatopen(!chatopen)
    }

    const handleSend = e => {
        const get = props.getMessage
        get(textRef.current.value)
    }
    const messages = [
        'heoy', 'fad'
    ]
    return (<LocaleContext.Consumer>
        {(value) => {
            if (value === "en") {
                return <S.chatcon>
                    <div className="chat-box" style={chatopen ? show : hide}>
                        <div className="header">How can we help you?</div>
                        <div className="msg-area">
                            {/* {
                            messages.map((msg, i) => (
                                i % 2 ? (
                                    <p className="right"><span>{msg}</span></p>
                                ) : (
                                    <p className="left"><span>{msg}</span></p>
                                )
                            ))
                        } */}
                            <a target='_blank' href="https://wa.me/+447801684280"><img src={Whatsappicon} /> Whatsapp</a>
                            <a target='_blank' href="tel:+44 7801 684280"><img src={Telephone} /> Call us</a>
                            <a target='_blank' href="https://www.instagram.com/gelecekivf/"><img src={InstaColor} /> İnstagram</a>
                        </div>
                        {/* <div className="footer">
                        <input type="text" ref={textRef} />
                        <button onClick={handleSend}><i className="fa fa-paper-plane"></i></button>
                    </div> */}
                    </div>
                    <div className="pop">
                        {chatopen ? <img
                            onClick={toggle}
                            src={Close}
                            alt="" /> : <Minik onClick={toggle} />}
                        {/* {chatopen?<img 
                    onClick={toggle} 
                    src={Close} 
                    alt="" />: <img 
                    onClick={toggle} 
                    src="https://p7.hiclipart.com/preview/151/758/442/iphone-imessage-messages-logo-computer-icons-message.jpg" 
                    alt="" />} */}

                    </div>
                </S.chatcon>
            } else if (value === 'tr') {
                return <S.chatcon>
                    <div className="chat-box" style={chatopen ? show : hide}>
                    <div className="header">Size nasıl yardımcı olabiliriz?</div>
                        <div className="msg-area">
                            {/* {
                        messages.map((msg, i) => (
                            i % 2 ? (
                                <p className="right"><span>{msg}</span></p>
                            ) : (
                                <p className="left"><span>{msg}</span></p>
                            )
                        ))
                    } */}
                            <a target='_blank' href="https://wa.me/+905532540057"><img src={Whatsappicon} /> Whatsapp</a>
                            <a target='_blank' href="tel:+90 (242) 324 25 26"><img src={Telephone} /> Bizi Arayın</a>
                            <a target='_blank' href="https://www.instagram.com/antalyagelecektupbebek/"><img src={InstaColor} /> İnstagram</a>
                        </div>
                        {/* <div className="footer">
                    <input type="text" ref={textRef} />
                    <button onClick={handleSend}><i className="fa fa-paper-plane"></i></button>
                </div> */}
                    </div>
                    <div className="pop">
                        {chatopen ? <img
                            onClick={toggle}
                            src={Close}
                            alt="" /> : <Minik onClick={toggle} />}
                        {/* {chatopen?<img 
                onClick={toggle} 
                src={Close} 
                alt="" />: <img 
                onClick={toggle} 
                src="https://p7.hiclipart.com/preview/151/758/442/iphone-imessage-messages-logo-computer-icons-message.jpg" 
                alt="" />} */}

                    </div>
                </S.chatcon>
            }
        }
        }
    </LocaleContext.Consumer>
    )
}

// export default PopChat