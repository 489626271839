
import { Link } from 'react-router-dom';
import S from './style'
import TranslateStatic from '../../locales/TranslateStatic';
import AysenurIMG from '../../assets/ekip/aysenururfan.png'
import Icv from './icv';
export function Aysenur() {
  return (
    <S.Wrapper>
      <S.Top>
        {/* <S.Header>
          <TranslateStatic string="aboutUsH2" />
        </S.Header> */}
        <S.Span>
          <Link to="./"><TranslateStatic string="home" /></Link> > <TranslateStatic string="teamHeader" />
        </S.Span>
      </S.Top>
      {/* <S.Sertifika>
        <Certification />
      </S.Sertifika> */}
      <S.Section>
        <S.Izgara>
          <div className='rowi'>
            <div className='items'>
              <img src={AysenurIMG}></img>
              <h2>Ayşenur Avcı</h2>
              <div className='content'>
                <article><TranslateStatic string="Aysenur" /></article>
                {/* <article>Üroloji Uzmanı</article> */}
                {/* <Link to='/aysenuravci'>Ayrıntılar</Link> */}

              </div>

            </div>
          </div>
        </S.Izgara>
        <div className='ozgecmis'>
          <p>
            1987 yılında Ankara’da doğdu. Lise eğitimini 2005 yılında Antalya Almanca Yabancı Dil Ağırlıklı 75. Yıl
            Cumhuriyet Fen Lisesi’nde tamamladı. 2010 yılında İstanbul’da Marmara Üniversitesi Fen- Edebiyat
            Fakültesi Biyoloji bölümünden mezun oldu. Aynı zamanda Biyoloji Öğretmenliği Pedagojik
            Formasyon eğitimini tamamladı. 2018 yılında İstanbul’da Maltepe Üniversitesi Klinik
            Embriyoloji  Tezli Yüksek Lisans Programını bitirdi. 2010 yılından itibaren Özel Gelecek Tüp Bebek
            Merkezi’nde embriyolog olarak görev yapmaktadır.
          </p>
        </div>

        <div>

          <h3>Yayınlar</h3>
          <ul>
            <li>Yüksek lisans tezi: Açıklanamayan İnfertilite Olgularında IVF ve ICSI Sonuçlarının Karşılaştırılması</li>
            <li>Kendirci A, Özçelik T N, Urfan A, Dönmez L, Isikoglu M. Characteristic cytoplasmic morphology of oocytes in endometriosis patients and its effect on outcome of ART cycles  IVF Lite Mar-Apr;1(2):88-93 (2014). DOI: 10.4103/2348-2907.140123</li>
            <li>Isikoglu M, Urfan Avci A, Koksal CD, Orta S Think Twice before Abdominal Delivery: Adverse Effects of Caesarean Section on Fertility and on Embryo Transfer Procedure Obstetrics and Gynecology International Journal. 2017 8(2): : 00285. DOI: 10.15406/ogij.2017.08.00285</li>
            <li>Isikoglu M, Aydinuraz B, Avci A, Kendirci Ceviren A Modified natural protocol seems superior to natural and artificial protocols for preparing the endometrium in frozen embryo transfer cycles Minerva Ginecol. 2020 May 13. doi: 10.23736/S0026-4784.20.04570-0. Online ahead of print</li>
            <li>Isikoglu M, Avci A, Kendirci Ceviren A, Aydınuraz B, Ata B Conventional IVF revisited: Is ICSI better for non-male factor infertility? Randomized controlled double blind study J Gynecol Obstet Hum Reprod. 2021 Sep;50(7):101990. doi: 10.1016/j.jogoh.2020.101990. Epub 2020 Nov 19.</li>
            <li>Isikoglu M, Kendirci Ceviren A, Cetin T, Avci A, Aydinuraz B, Karabay Akgul O, Karaca M Comparison of ICSI and conventional IVF in non-male factor patients with less than four Oocytes Arch Gynecol Obstet, (Baskıda)</li>
            <li>Kendirci A, Özçelik TN, Urfan A, Dönmez L, Işıkoğlu M, ÜYTE Uygulamalarında ardışık ve ardışık olmayan kültür ortamlarının kıyaslanması. İkinci Uluslararası Klinik Embriyoloji Derneği Kongresi, Sarıgerme, 23-25 Eylül 2011) (poster sunumu)</li>
            <li>Kendirci A, Özçelik TN, Urfan A, Dönmez L, Işıkoğlu M. Typical morphology of oocytes in endometriosis patients and its effect on ART outcome. 28th Annual meeting of ESHRE June 2012 Istanbul (poster sunumu) Abstract book of the 28th ESHRE Annual Meeting, Turkey 1 July - 4 July 2012 Volume 27 suppl 2 2012</li>
            <li>Urfan A, Isikoglu M, Kendirci Cevien A, Aydinuraz B, Urshanova A. Conventional IVF revisited: is ICSI really better for non-male factor infertility? Randomized controlled double blind study. 33th Annual meeting of ESHRE June 2017 Geneva (poster sunumu) Abstract book of the 33th ESHRE Annual Meeting, Switzerland 2 July - 5 July 2017 </li>
            <li>Isikoglu Mete, Aydinuraz Batu; Urshanova Amilya, Urfan Avci Aysenur Hysteroscopic evaluation is justified in recurrent miscarriage cases. ESGE 26th Annual Congress 18-21 October 2017 Antalya ORAL </li>
            <li>Isikoglu Mete, Aydinuraz Batu; Urshanova Amilya, Urfan Avci Aysenur  Hysteroscopic evaluation is justified in recurrent implantation failures. ESGE 26th Annual Congress 18-21 October 2017 Antalya POSTER 25</li>
            <li>Işıkoğlu M, Tanriverdi Ozcelik N, Koksal C, Adiguzel D, Aslanbas S, Yazan S, Avci A “Arcuate Uterus And Pregnancy Loss: Which Is The Cause And Which Is The Outcome?” TSRM 2014, 6.Ulusal Üreme Endokrinolojisi ve İnfertilite Kongresi, 06 – 09 Kasım, Antalya (2014) (Sözlü sunu)</li>
            <li>Batu Aydınuraz, Mete Işıkoğlu, Ayşe Kendirci Çeviren, Ayşenur Urfan Avcı, Clinical efficacy of endometrial receptivity array: case series. TSRM 2016, 7. Ulusal Üreme Endokrinolojisi ve İnfertilite Kongresi, 17– 20 Kasım, Antalya (2016) (Sözlü sunu) </li>

          </ul>
          <h3>Üyelikler</h3>
          <ul>
            <li>Tüp Bebek Merkezleri Derneği(TBMD)</li>
            <li>Üreme Tıbbı ve Cerrahisi Derneği (ÜTCD)</li>
            <li>European Society of Human Reproduction and Embryology (ESHRE)</li>
          </ul>
        </div>



      </S.Section>
    </S.Wrapper >
  );
}
