import React, { useState } from 'react';
import Telephone from '../../assets/telephone.png'
import Arrow from '../../assets/right-arrow.png'
import Video from '../../assets/video.mp4'
import S from './style'
import TranslateStatic from '../../locales/TranslateStatic'
import Readmore from './readmore'
class Carousel extends React.Component {

  constructor(props) {
    super(props);

    this.prevItem = this.prevItem.bind(this);
    this.nextItem = this.nextItem.bind(this);

    this.state = {
      counter: 0,
      timeout: setTimeout(this.nextItem, this.props.timeoutTime),
    };
  }

  prevItem() {
    var prevItem = this.state.counter - 1 < 0 ? this.props.items.length - 1 : this.state.counter - 1;

    clearTimeout(this.state.timeout);
    this.setState({
      counter: prevItem,
      timeout: setTimeout(this.nextItem, this.props.timeoutTime)
    });
  }

  nextItem() {
    var nextItem = this.state.counter + 1 < this.props.items.length ? this.state.counter + 1 : 0;

    clearTimeout(this.state.timeout);
    this.setState({
      counter: nextItem,
      timeout: setTimeout(this.nextItem, this.props.timeoutTime)
    });
  }

  render() {
    const items = this.renderItems(this.props.items);

    return (
      <S.carousel>
        {/* <S.carouselPrev onClick={this.prevItem}></S.carouselPrev> */}
        {items}
        {/* <S.carouselNext onClick={this.nextItem}></S.carouselNext> */}
      </S.carousel>
    );
  }

  renderItems(items) {
    return items.map((item, index) => this.renderItem(item, (this.state.counter === index)));
  }

  renderItem(item, current) {
    return (
      <CarouselItem {...item} current={current}></CarouselItem>
    );
  }
}

class CarouselItem extends React.Component {
  constructor(props) {
    super(props);

    this.openEvent = this.openEvent.bind(this);


    this.state = {
      open: false
    };
  }

  openEvent() {
    console.log(this.state.open)
    this.setState({
      open: !this.state.open
    });
  }
  render() {
    console.log(this.props.caption)
    return (
      <S.carouselItem className={(this.props.current ? 'current' : '')}>
        {/* <img src={this.props.image} role="presentation" /> */}
        <video src={Video} autoPlay muted loop={true} role="presentation" />
        <S.carouselHeading className={(this.props.current ? '' : 'show')}>
          <div className="left">

            <h2>{this.props.title}</h2>
            {/* {this.props.caption?<Readmore metin={this.props.caption}/>:false} */}
           <Readmore/>
            {/* <p className={(this.state.open ? 'pAuto' : '')}>
              {this.props.caption}
            </p><span onClick={() => this.openEvent()}>Devamı...</span> */}
            <S.RandevuWrap>
              <a href="tel:+90 (242) 324 25 26"><TranslateStatic string="sliderRandevuAlButton" /><img className="tele" src={Telephone} /></a>
              <a target="_blank" href="https://www.google.com/maps?ll=36.860364,30.779435&z=16&t=m&hl=tr&gl=US&mapclient=embed&cid=17978173856013657874"><TranslateStatic string="sliderLocationButton" /> <img className="tele" src={Arrow} /></a>
            </S.RandevuWrap>
          </div>
          {/* <div className="right">
            <h2>
            <TranslateStatic string="sliderEtkinlikTitle" />
            </h2>
            <TranslateStatic string="sliderEtkinlikAciklama" />
          </div> */}
        </S.carouselHeading>
      </S.carouselItem>
    );
  }
}

const carouselData = {
  timeoutTime: 5000,
  items: [
    {
      key: 4,
      image: Video,
      title: <TranslateStatic string="sliderTitle" />,
      caption: <TranslateStatic string="sliderCaption" />,
    },

  ]
};

function slid() {

  return <Carousel {...carouselData} />
}

export default slid