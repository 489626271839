
import { Link } from 'react-router-dom';
import S from './style'
import TranslateStatic from '../../locales/TranslateStatic';
import YumurtlamaImg from '../../assets/donduraraksaklama.jpeg'
export function DondurarakSaklama() {
  return (
    <S.Wrapper>
      <S.Top>
        {/* <S.Header>
          <TranslateStatic string="aboutUsH2" />
        </S.Header> */}
        <S.Span>
          <Link to="./"><TranslateStatic string="home" /></Link> > <Link><TranslateStatic string="dondurarak saklama" /></Link>
        </S.Span>
      </S.Top>
      {/* <S.Sertifika>
        <Certification />
      </S.Sertifika> */}
      <S.Section>
        <div className='banner'>
          <div className='baslik'>
            <h1>
              <TranslateStatic string="dondurarak saklama" />
            </h1>
          </div>
          <div className='imgwrap'>

            <img className='foregroundImg' src={YumurtlamaImg} />
            <img className="backgroundImg" src={YumurtlamaImg} />
          </div>



        </div>


        <h3><TranslateStatic string="dondurarakSaklamaH1" /></h3>
        <p>
          <TranslateStatic string="dondurarakSaklamaP2" />
        </p>
        <ul>
          <li><TranslateStatic string="dondurarakLi1" /></li>
          <li><TranslateStatic string="dondurarakLi2" /></li>
          <li><TranslateStatic string="dondurarakLi3" /></li>
          <li><TranslateStatic string="dondurarakLi4" /></li>
        </ul>
        <h3><TranslateStatic string="dondurarakSaklamaH2" /></h3>
        <p>
          <TranslateStatic string="dondurarakSaklamaP3" />
        </p>
        <p>
          <TranslateStatic string="dondurarakSaklamaP3-1" />
        </p>
        <ul>
          <li><TranslateStatic string="dondurarakLi5" /></li>
          <li><TranslateStatic string="dondurarakLi6" /></li>
          <li><TranslateStatic string="dondurarakLi7" /></li>

        </ul>
        <p>
          <TranslateStatic string="dondurarakSaklamaP6" />
        </p>
        <p><b>
        <TranslateStatic string="dondurBold" />
        </b></p>
        <h3><TranslateStatic string="dondurarakSaklamaH3" /></h3>
        <p>
          <TranslateStatic string="dondurarakSaklamaP4" />
        </p>
        {/* <p>
          <b> <TranslateStatic string="dondurarakSakalamNot" /></b>
        </p> */}
      </S.Section>
    </S.Wrapper >
  );
}
