
import { Link } from 'react-router-dom';
import S from './style'
import TranslateStatic from '../../locales/TranslateStatic';
import { LocaleContext } from '../../locale-context';
import IMG from '../../assets/ekip/adil.png'
export function Adil() {
  return (
    <S.Wrapper>
      <S.Top>
        {/* <S.Header>
          <TranslateStatic string="aboutUsH2" />
        </S.Header> */}
        <S.Span>
          <Link to="./"><TranslateStatic string="home" /></Link> > <TranslateStatic string="teamHeader" />
        </S.Span>
      </S.Top>
      {/* <S.Sertifika>
        <Certification />
      </S.Sertifika> */}
      <S.Section>
        <S.Izgara>
          <div className='rowi'>
            <div className='items'>
              <img src={IMG}></img>
              <h2>Prof. Dr. Adil Gökalp</h2>
              <div className='content'>
                <p><TranslateStatic string="Adil" /></p>
                {/* <article>Kadın Hastalıkları ve Doğum Uzmanı</article> */}
                {/* <article>Endoskopi Uzmanı ( Bachelor in Endoscopy )</article> */}

              </div>

            </div>
          </div>
        </S.Izgara>
        {/* <div className='ozgecmis'>
          <p>Dr. Batu Aydınuraz 6 Haziran 1973 tarihinde mikrobiyolog bir anne ve fizik profesörü bir babanın ikinci çocuğu olarak Ankara’da dünyaya geldi.</p>

          <p>İlk öğrenimini Alparslan İlkokulu’nda orta ve lise öğrenimini T.E.D. Ankara Koleji’nde tamamladı.1992 yılında,dahiliye uzmanı dedesi ve genel cerrahi uzmanı olan ablasının izinden giderek Ankara Üniversitesi Tıp Fakültesi’nde tıp eğitimine başladı ve 1998 yılında “Tıp Doktoru” olarak mezun oldu. Aynı yıl girdiği Tıpta Uzmanlık Sınavı sonrasında Ankara Üniversitesi Tıp Fakültesi Kadın Hastalıkları ve Doğum Anabilim Dalı’nda ihtisasa başladı.</p>
          <br />
          <p>2003 Haziran ayında uzmanlığını alan Dr.Aydınuraz, Haziran 2003 tarihinden Nisan 2010 tarihine kadar Prof.Dr.Hakan Şatıroğlu ile birlikte Gen-ART Tüp Bebek Merkezi’nde kadın hastalıkları ve doğum uzmanı ,HŞ Tüp Bebek ve MEDICANA International Ankara Hastanesi Tüp Bebek Merkezi’nde IVF sorumlu hekimi olarak çalışmıştır.Yine bu dönemde (2004-2005) Azerbaycan Bakü’de ülkenin ilk tüp bebek merkezinin kurulması ve ilk hastaların tedaviye alınmasında aktif görev almıştır.Mart 2010 ile Aralık 2015  tarihleri arasında kurucu ve sorumlu hekim olarak MEMORIAL Antalya Hastanesi Tüp Bebek Merkezi’nde üreme tıbbı ve infertilite alanında kendi ekibi ile çalışmalarına devam etmiştir.Ocak 2016 tarihinden bu yana Dr.Mete Işıkoğlu ile birlikte Özel ANTALYA GELECEK TÜP BEBEK MERKEZİ’nde üreme sağlığı ve tüp bebek alanındaki çalışmalarına devam etmektedir. </p>

          <p>Dr.Aydınuraz, Ecz.Öznur Aydınuraz ile evlidir;Defne ve Deren isimlerinde ikiz kız çocuk babasıdır.</p>
        </div> */}
        <LocaleContext.Consumer>
          {(value) => {
            if (value === "tr") {
              return <div>
                <h3>Kişisel Bilgiler</h3>

                <p>Dr Adil Gökalp 1960 yılında Lüleburgaz’da dogdu.
                1983 tarihinde Akdeniz Üniversitesi Tıp Fakültesinden mezun oldu. Aynı yıl teğmen rütbesi ile Türk Silahlı Kuvvetlerinde göreve başladı.
                1987 - 1992 tarihleri arasında GATA'da üroloji Uzmanı olarak görev yaptı.2001 yılında doçent, 2010 yılındaProfesör oldu.
                 </p>
                <p>2003 yılında Türk Silaklı Kuvvetlerinden albay rütbesiyle emekli oldu.</p>
              <p>12 yıldır Gelecek Tüp Bebek Merkezinde Üroloji Uzmanı olarak görev yapmaktadır.</p>

              </div>

            } else if (value === 'en') {
              return <div>
                <h3>Personal Information</h3>
               
                <p>Dr Adil Gökalp was born in Lüleburgaz in 1960.
                He graduated from Akdeniz University Faculty of Medicine in 1983. In the same year, he started working in the Turkish Armed Forces with the rank of lieutenant.
                He worked as a urology specialist at GATA between 1987 and 1992. He became an associate professor in 2001 and a professor in 2010.
                 </p>
                <p>He retired from the Turkish Armed Forces in 2003 with the rank of colonel.</p>
              <p>He has been working as a Urology Specialist at the Future In Vitro Fertilization Center for 12 years.</p>

              </div>
            }
          }}
        </LocaleContext.Consumer>

      </S.Section>
    </S.Wrapper >
  );
}
