import styled from "styled-components";

const S ={

    Wrapper:styled.div`
    display: grid;
    width:100%;
    gap: 0.2rem;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    grid-auto-rows: 80px;
    .card{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // background:#01aecb;
        color: #fff;
        // box-shadow: rgba(3, 8, 20, 0.1) 0px 0.15rem 0.5rem, rgba(2, 8, 20, 0.1) 0px 0.075rem 0.175rem;
        height: 100%;
        width: 100%;
        border-radius: 4px;
        // transition: all 100ms;
        overflow: hidden;
        padding: 0;
        margin: 0;
        img{
            width:100%;
            height:100%;
            overflow: hidden;
            object-fit:cover;
            background:#01aecb;
            &:hover{
                // transition: all 100ms;
                opacity:0.5;
            }
        }
       
    }
    @media screen and (min-width: 600px) {
        .card-tall {
          grid-row: span 2 / auto;
        }
      
        .card-wide {
          grid-column: span 2 / auto;
        }
      }
    `
}

export default S;