
import { Link } from 'react-router-dom';
import S from './style'
import TranslateStatic from '../../locales/TranslateStatic';
import Accordion from '../../components/references/accordion'
export function SSS() {
  return (
    <S.Wrapper>
      <S.Top>

        <S.Header>

          <TranslateStatic string="SSSBaslik" />
        </S.Header>
      </S.Top>
      <S.Section>
        <Accordion />
      </S.Section>
    </S.Wrapper >
  );
}
