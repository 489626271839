import styled from "styled-components";

const S = {
    Wrapper: styled.section`
    width: 100%;
    // height:200px;
    overflow:hidden;
    @media (max-width:648px){
        height:auto;
    }
    .imgbanner{
        display:flex;
        flex-direction:row;
        justify-content:flex-end;    
        position:relative;
        height:100%;
        .Gradient{
            width:100%;
            height:100%;
            z-index:1;
            // background: rgb(0,0,0);
            background: linear-gradient(90deg, rgba(1,174,203,1) 34%, rgba(1,174,203,0) 72%);
            color:white;
            padding:70px 50px;
            box-sizing:border-box;
            h1{
                font-size:3em;
                margin:0;
            }
            @media (max-width:648px){
                h1{
                    font-size:2em;
                }
            }
        }
        img{
            width:80%;
            height:400px;
            object-fit:cover;
            z-index:0;
            position:absolute;
            object-position: 0px -300px;
            @media (max-width:648px){
                height:100%;
            }
        }
    }
    
    `,
  
}

export default S