
import { Link } from 'react-router-dom';
import S from './style'
import TranslateStatic from '../../locales/TranslateStatic';

import Gen1 from '../../assets/genetiktarama/1.jpeg'
import Gen2 from '../../assets/genetiktarama/2.jpeg'
import Gen3 from '../../assets/genetiktarama/3.jpeg'
import Gen4 from '../../assets/genetiktarama/4.jpeg'


export function GenetikTarama() {
  return (
    <S.Wrapper>
      <S.Top>
        {/* <S.Header>
          <TranslateStatic string="aboutUsH2" />
        </S.Header> */}
        <S.Span>
          <Link to="./"><TranslateStatic string="home" /></Link>  >  <Link><TranslateStatic string="genetik tarama" /></Link>
        </S.Span>
      </S.Top>
      {/* <S.Sertifika>
        <Certification />
      </S.Sertifika> */}
      <S.Section>
        <div className='banner'>
          <div className='baslik'>
            <h1>
              <TranslateStatic string="genetik tarama" />
            </h1>
            <h3>
              <TranslateStatic string="genetik tarama2" />
            </h3>
          </div>
          <div className='imgcontainer'>
            <div className='imgwrap'>
              <span>1</span>
              <img className='foregroundImg' src={Gen1} />
              <img className="backgroundImg" src={Gen1} />
            </div>
            <div className='imgwrap'>
              <span>2</span>
              <img className='foregroundImg' src={Gen2} />
              <img className="backgroundImg" src={Gen2} />
            </div>
            <div className='imgwrap'>
              <span>3</span>
              <img className='foregroundImg' src={Gen3} />
              <img className="backgroundImg" src={Gen3} />
            </div>
            <div className='imgwrap'>
              <span>4</span>
              <img className='foregroundImg' src={Gen4} />
              <img className="backgroundImg" src={Gen4} />
            </div>
          </div>
        </div>
        <p style={{textAlign:"right"}}>Görüntüler Gelecek Tüp Bebek merkezine aittir.</p>

        <p>
          <TranslateStatic string="genetikTaramaUsP1" />
        </p>
        <h3><TranslateStatic string="genetikTaramaUsh3-1" /></h3>
        <p>
          <TranslateStatic string="genetikTaramaUsP2" />
        </p>
        <h3><TranslateStatic string="genetikTaramaUsh3-2" /></h3>
        <p>
          <TranslateStatic string="genetikTaramaUsP3" />
        </p>
        <p>
          <TranslateStatic string="genetikTaramaUsP3-1" />
        </p>
        <h3><TranslateStatic string="genetikTaramaUsh4-1" /></h3>
        <p>

          <TranslateStatic string="genetikTaramaUsP4-1" />
        </p>


        <p>

          <TranslateStatic string="genetikTaramaUsP4-2" />
        </p>
        <p>

          <TranslateStatic string="genetikTaramaUsP4-3" />
        </p>


      </S.Section>
    </S.Wrapper >
  );
}
