import styled from "styled-components";

const S = {
    Wrapper: styled.section`
    // display: grid;
    width: 100%;
    // grid-template-columns: repeat(2, 1fr);
    // grid-template-rows: auto auto;
    // grid-template-areas:"headerleft headerright"
    // "content content";
    // @media (max-width:648px){
    //     grid-template-columns: 1fr;
    // }
    `,
    Top: styled.div`
    grid-area: headerleft;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding:40px 40px;
    align-items: left;
    background-color: #EAEAF6;
    @media (max-width:648px){
        padding:20px;
    }
    `,
    Header: styled.h1`
    color:black;
    font-size: 20px;
    // background-color: #d41031;
    margin:0;
    /* padding:40px 40px 10px 40px; */
    @media (max-width:648px){
        font-size: 16px;
    }
    `,
    Span: styled.div`
    color:black;
    font-size: 14px;
    // background-color: #d41031;
    margin:0;
    text-transform: capitalize;
    opacity:.5;
    a{
        color:black;
        text-decoration: none;
    }
    /* padding: 10px 40px 40px 40px; */
    `,
    Circle: styled.div`
    position:absolute;
    width:400px;
    height:400px;
    background:#01aecb;
    border-radius:100%;
    filter: blur(1.5rem);
    opacity:0.2;
    top:300px;
    left:-150px;
    `,
    Section: styled.section`
    margin: 40px;
    grid-area: content;
    .banner{
        display:flex;
        flex-direction:row;
        position:relative;
        align-items:center;
        @media (max-width:648px){
            flex-direction:column;
        }
        .imgwrap{
            position:relative;
            width:50%;
            height:300px;
            @media (max-width:648px){
                width:100%;
            }
            .foregroundImg{
                width:100%;
                cursor: pointer;
                position: relative;
                z-index: 2;
                border-radius:16px;

                height:300px;
                object-fit:cover;
                
            }
            .backgroundImg{
                height:300px;
                object-fit:cover;
                width:100%;
                position: absolute;
                top: 2px;
                left: 0;
                transform: scale(1.05);
                transition: all 0.5s ease;
                opacity: 1;
                filter: blur(24px);
                z-index: 1;
            }
        }
        
			
        .baslik{
            /* From https://css.glass */
           
            width:50%;
            // position:absolute;
            // bottom:20px;
            // right:20px;
            // padding:40px;
            @media (max-width:648px){
                width:100%;
            }
            h1{
                color:#01aecb;
                font-size:4em;
                font-weight:900;
                margin:0px;
                @media (max-width:648px){
                    font-size:2em;
                    width:100%;
                        }
            }
        }
    }
    h3{
        color:#01aecb;
        font-size:2em;
    }
    `,
    Baslik:styled.h1`
    font-size:3em;
    text-align:center;
    `,
    Sertifika: styled.div`
    grid-area: headerright;
    // background-color: #d41031;
    display: flex;
    flex-direction: row;
    justify-content: right;
    padding:0px 40px;
    align-items: center;
    @media (max-width:648px){
        padding:20px;
    }
    svg,img{
        margin:5px;
        width:50px;
        height: 70px;
        filter: invert(100%);
        @media (max-width:648px){
            width:30px;
        height: 30px;
    }
    }
    `,
    Izgara: styled.div`
    display: flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    .rowi{
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:center;
        width:100%;
        &:first-child{
            .items{
                flex-grow:1;
            }
        }
        .items{
            margin:10px;
            padding:20px;
            // background:red;
            display:flex;
            flex-direction:column;
            align-items:center;
            flex-basis:20%;
            
            img{
                width:100px;
                height:100px;
                // height:200px;
                object-fit:cover;
                object-position:top;
                border-radius:100%;
            }
            h2{
                margin-bottom:0;
                color:#01aecb;
                font-size:14px;
            }
            .content{
                display:flex;
                flex-direction:column;
                align-items:center;
                
                p{
                    margin:10px 0px;
                    color:#01aecb;
                }
                
            }
        }
    }
    `
}

export default S