
import { Link } from 'react-router-dom';
import S from './style'
import TranslateStatic from '../../locales/TranslateStatic';
import YumurtlamaImg from '../../assets/yumurtlama.jpeg'
export function Yumurtlama() {
  return (
    <S.Wrapper>
      <S.Top>
        {/* <S.Header>
          <TranslateStatic string="aboutUsH2" />
        </S.Header> */}
        <S.Span>
          <Link to="./"><TranslateStatic string="home" /></Link> > <Link><TranslateStatic string="yumurtlamanın uyarılması" /></Link>
        </S.Span>
      </S.Top>
      {/* <S.Sertifika>
        <Certification />
      </S.Sertifika> */}
      <S.Section>
        <div className='banner'>
          <div className='baslik'>
            <h1>
              <TranslateStatic string="yumurtlamanın uyarılması" />
            </h1>
            <h3>
            <TranslateStatic string="yumurtlamanın uyarılması2" />
           
            </h3>
          </div>
          <div className='imgwrap'>

          <img className='foregroundImg' src={YumurtlamaImg} />
          <img className="backgroundImg" src={YumurtlamaImg} />
          </div>



        </div>

        <p>
          <TranslateStatic string="yumurtlamaUsP1" />
        </p>
        <h3><TranslateStatic string="yumurtlamaUsh3-1" /></h3>
        <p>
          <TranslateStatic string="yumurtlamaUsP2" />
        </p>
        <p>
          <TranslateStatic string="yumurtlamaUsP2-1" />
        </p>
        <p>
          <TranslateStatic string="yumurtlamaUsP2-2" />
        </p>
        <h3><TranslateStatic string="yumurtlamaUsh3-2" /></h3>
        <p>
          <TranslateStatic string="yumurtlamaUsP3" />
        </p>
        <p>
          <TranslateStatic string="yumurtlamaUsP3-1" />
        </p>
        <p>
          <TranslateStatic string="yumurtlamaUsP3-2" />
        </p>
        
       
      </S.Section>
    </S.Wrapper >
  );
}
