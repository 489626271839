import styled from "styled-components";
import { Link } from "react-router-dom";
const S = {
    Wrapper: styled.section`
    display: flex;
    flex-direction:column;
    width: 100%;
    height:auto;
    `,
    Section: styled.section`
    margin: 40px;
    display: flex;
    flex-direction:column;
    align-content: center;
    align-items: center;
    @media (max-width:648px){
        margin: 5px;
    }
    .item-wrapper{
        height:auto;
        width: 100%;
        display: flex;
        box-sizing:border-box;
        flex-direction: column;
        align-items: center;
        padding: 40px;
        box-shadow: 9px 15px 22px 5px rgba(0,0,0,0.16); 
        // border: 2px solid #e5e5e5;
        border-radius: 10px;
        transition: 200ms;
        @media (max-width:648px){
           padding:10px;
        }
        p{
            &:nth-child(1){
                grid-area: header;
            }
        }
        .detail{
            
            text-align:center;
           
            

        }
        /* &:hover{
            transition: 200ms;
            -webkit-box-shadow: 9px 15px 22px 5px rgba(0,0,0,0.16); 
            box-shadow: 9px 15px 22px 5px rgba(0,0,0,0.16);
            border: 0px solid #e5e5e5;
        } */
        .item-image{
            width:100%;
            box-sizing:border-box;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @media (max-width:648px){
               max-width:300px;
            }
            .custom-paging{
                bottom:-50px;
            }
            img{
                /* margin:20px; */
                &:first-child{
                    /* width: 100%; */
                    max-height: 400px;
                    margin: 0px auto;
                }
                width:50%;
                object-fit: contain;
                max-height: 300px;
                margin: 0px auto;
                /* height: 100%; */
            }
        }
        
        }
        @media (max-width:648px){
            padding:20px;
            /* height: auto; */
            flex-direction: column;
        }
    }
    `,
    Items:styled.div`
    display: flex;
    flex-direction:row;
    justify-content: center;
    `,
    InItem:styled(Link)`
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-content: center;
        align-items: center;
        text-align:center;
        text-decoration: none;
        color:black;
        margin-top:10px;
        border-radius:10px;
        padding: 2px;
        border:1px solid white;
        transition: 500ms;
        &:hover{
            transition: 500ms;
            border:1px solid red;
        }
        &:visited{
            color:black;
        }
    `
}

export default S