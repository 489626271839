import styled from "styled-components";

const S = {
    Wrapper:styled.div`
    display:flex;
    flex-direction:row;
    padding:0px 200px 50px 200px;
    justify-content:space-evenly;
    flex-wrap:wrap;
    @media (max-width:648px){
        padding:50px 10px;
        flex-direction:column;
        align-items:center;
    }
    `,
    Kare:styled.div`
    position: relative;
    width:400px;
    height:400px;
    background:#00A9C4;
    border-radius:100%;
    @media (max-width:648px){
        width:200px;
        height:200px;
        display:flex;
        align-content:center;
    }
    `,
    Abss:styled.div`
    position:absolute;
    z-index:1;
    left:-60px;
    top:-20px;
    @media (max-width:648px){
        left:-20%;
        top:auto;
        position:relative;
    }
    `,
    Object:styled.img`
    // transform: rotate(20deg);
   
    width:600px;
    // filter: drop-shadow(-80px 40px 60px rgba(0, 0, 0, 0.53));
    // transition: width 2s, height 2s, transform 1s;
    filter: drop-shadow(-3px 43px 30px rgba(0, 0, 0, 0.53));

    // &:hover{
    //     transition:transform 0.5;
    //     transform:rotate(10deg);
    // }
    @media (max-width:648px){
        width:300px;
    }
    `,
    Sag:styled.div`
        flex-basis:50%;
        display:flex;
        flex-direction:column;
        justify-content:space-evenly;
        h1{
            font-size:3em;
            color:rgb(212, 16, 49);
        }
        p{
            font-size:2.9em;
            font-weight:900;
            b{
                background: -webkit-linear-gradient(45deg, #00A9C4, #00ff95 80%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    `,

  
}

export default S;