import styled from "styled-components";
import Logo from '../../assets/logo.svg'
const S = {
    Wrapper: styled.footer`
    display: flex;
    flex-direction: column;
    z-index: 9;
    background-color: #EAEAF6;
    padding:0px 40px; 
    margin-top:8em;
    @media (max-width:648px){
       padding:10px;
    }
    .copyright{
        opacity:0.7;
        display: flex;
        padding: 20px 0px;
        flex-direction: row;
        justify-content: space-between;
        // background-color: #ff4b66;
        border-top:1px solid white;
        color:#677294;
        font-size:12px;
        margin:0px 40px;
        a{
            text-decoration: none;
            color:black;
            transition: 500ms ease;
            &:visited{
                color:black;
            }
            &:hover{
                color:#00A9C4;
                transition: 500ms ease;
            }
        }
    }
    p{
        color:white;
        width:70%;
    }
    `,
    Ust: styled.div`
    /* background-image: linear-gradient(white,white),
    url(${Logo}); */
    /* background-blend-mode: saturation; */
    padding: 0px 40px 40px 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* flex-direction: row; */
    /* justify-content: space-between; */
    grid-gap: 40px;
    margin-bottom: 20px;
    height: auto;
    position: relative;
    font-size:14px;
    @media (max-width:648px){
        grid-template-columns: 1fr;
        padding: 0px 10px 10px 10px;
            }
    &::after{
        content: "";
        background-size:cover;
        background-image: url(${Logo});
        position:absolute;
        top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: -1;  
      /* filter: opacity(0.5); */
    //   filter: contrast(200%) brightness(150%);
    //   opacity: 0.1;
    }
    &::before{
        // filter: grayscale(0.5);
        // filter: contrast(200%) brightness(150%);
    }
    h2{
        color:black;
    }
    iframe{
        border: none;
        width: 100%;
        height: 150px;
        border-radius:10px;
    }
    ul{
        list-style: none;
        padding: 0;
        margin: 0;
        color:#8A8A8A;
        li{
            padding-top: 10px;
        }
    }
    p{
        color:#8A8A8A;
    }
    .socialMedia{
        display:flex;
        flex-direction:row;
        width:50px;
        justify-content:space-between;
        // padding-left:20px;
        a{
            // margin:10px; 
            img{
                width:20px;
                filter: brightness(80%);
            }
            &:hover{
                filter: brightness(60%);
            }
        }
        
    }
    `,
    Img: styled.img`
    width:100%;
    object-fit:cover;
    position: absolute;
    z-index:-10;
    `,
    Logo: styled.img`
    width:350px;
    grid-area: logo;
    // padding:0px 10px;
    // filter: brightness(100);
    `,
    Memnun: styled.div`
    // background-color: #EAEAF6;
    // padding:20px 20px;
    position:relative;
    top:-50px;
    display:flex;
    // flex-direction:column;
    justify-content:center;
    border-radius:16px;
   
    .main{
        background-color: #00A9C4;
        display:flex;
        flex-direction:row;
        justify-content:flex-end;
        align-items:center;
        width:700px;
        padding:30px 45px;
        border-radius:16px;
        box-shadow:  20px 20px 60px #bebebe,
             -20px -20px 60px #ffffff;
             transition:500ms;
        @media (max-width:648px){
            flex-direction: column;
        }  
        &:hover{
            box-shadow:  20px 40px 80px #bebebe,
            -20px -20px 60px #ffffff;
            transition:500ms;
            }
        a{
            background:black;
            border-radius:25px;
            padding:20px 35px;
            text-decoration:none;
            color:white;
            transition:500ms;
            &:hover{
                background-color:white;
                color:black;
                transition:500ms;
            }
        }
        
    }
    `,
    Tex:styled.div`
    width:60%;
    @media (max-width:648px){
        width:100%;
    }  
    h3{
        color:white;
        padding:0px;
        margin:0px;
        font-weight:400;
    }
    p{
        font-size:12px;
        font-weight:300;
        width:90%;
    }
    `,
    Email:styled.div`
    // width:40%;
    height:40px;
    background:white;
    border-radius:40px;
    display:flex;
    flex-direction:row;
    align-items:center;
    overflow:hidden;
    padding:5px;
    input{
        border:0px;
        outline:none;
        width:200px;
        padding-left:20px;
    }
    button{
        background-color:#A2432B;
        border:0px;
        border-radius:40px;
        color:white;
        padding:10px 15px;
        transition:200ms;
        &:hover{
            transition:200ms;
            background-color:black;
        }
    }
    `
}

export default S