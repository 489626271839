
import { Link } from 'react-router-dom';
import S from './style'
import Pdfviewer from '../../components/pdfviewer'
import TranslateStatic from '../../locales/TranslateStatic';
export function Catalog() {
  return (
    <S.Wrapper>
      <S.Top>
        <S.Header>
        <TranslateStatic string="catalogH2" />
        </S.Header>
        <S.Span>
          <Link to="./"><TranslateStatic string="home" /></Link> > <TranslateStatic string="catalogH2" />
        </S.Span>
      </S.Top>
   
      <S.Section>
        
          <Pdfviewer/>
        
      </S.Section>
    </S.Wrapper>
  );
}
