
import { Link } from 'react-router-dom';
import S from './style'
import TranslateStatic from '../../locales/TranslateStatic';
export function Services() {
  return (
    <S.Wrapper>
      <S.Top>
        <S.Header>
          <TranslateStatic string="servicesH2" />
        </S.Header>
        <S.Span>
          <Link to="./"><TranslateStatic string="home" /></Link> > <TranslateStatic string="servicesHeader" />
        </S.Span>
      </S.Top>
      <S.Section>
        {/* <h3><TranslateStatic string="servicesh3-1" /></h3> */}
        <p>
          <TranslateStatic string="servicesP1" />
        </p>
        <h3><TranslateStatic string="servicesh3-2" /></h3>
        <p>
          <TranslateStatic string="servicesP2" />
        </p>
        <h3><TranslateStatic string="servicesh3-3" /></h3>
        <p>
          <TranslateStatic string="servicesP3" />
        </p>
        {/* <ul>
          <li><TranslateStatic string="servicesListItem1" /></li>
          <li><TranslateStatic string="servicesListItem2" /></li>
          <li><TranslateStatic string="servicesListItem3" /></li>
          <li><TranslateStatic string="servicesListItem4" /></li>
        </ul> */}
        <h3><TranslateStatic string="servicesh3-4" /></h3>
        <p>
          <TranslateStatic string="servicesP4" />
        </p>
        <h3><TranslateStatic string="servicesh4-4" /></h3>
        <p>
          <TranslateStatic string="servicesP4-1" />
        </p>
        <h3><TranslateStatic string="servicesh5-4" /></h3>
        <p>
          <TranslateStatic string="servicesP5-1" />
        </p>
        <p>
          <TranslateStatic string="servicesP6-1" />
        </p>
      </S.Section>
    </S.Wrapper>
  );
}
