import styled from "styled-components";

const S = {
    main: styled.div`
    z-index:12;
    section{
        width: 70px;
        // min-height: 100vh;
        height:70px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius:50%;
        border:10px solid #35e233;
        background:#35e233;
        box-sizing:border-box;
        overflow:hidden;
        .mask{
            position:fixed;
            z-index:20;
            border-radius:50%;
            border:10px solid #35e233;
            width:70px;
            height:70px;
            box-sizing:border-box;
            overflow:hidden;
        }
        div{
            width: 100%;
            height:100%;
            // overflow:hidden;
            transition: ease 1000ms;
            white-space: nowrap;
            // position: relative;
            // margin: 2rem;
                .main-image {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    
                    // position:relative;
                    transition: ease 1000ms;
                    // animation: mymove 2s infinite;
                    // @keyframes mymove {
                    //     0%
                    //         {
                    //             left: 0px;
                    //         }
                    //         40%
                    //         {
                    //             left: 100px;
                    //         }
                      
                    //   }
                    // animation: heartbeat 2s infinite;
                    // @keyframes heartbeat {
                    //     0%
                    //     {
                    //         transform: scale( .75 );
                    //     }
                    //     20%
                    //     {
                    //         transform: scale( 1 );
                    //     }
                    //     40%
                    //     {
                    //         transform: scale( .75 );
                    //     }
                    //     60%
                    //     {
                    //         transform: scale( 1 );
                    //     }
                    //     80%
                    //     {
                    //         transform: scale( .75 );
                    //     }
                    //     100%
                    //     {
                    //         transform: scale( .75 );
                    //     }
                    //   }
                }
        }
        .icon-left {
            position: absolute;
            top: 50%;
            left: -3%;
          }
          
          .icon-right {
            position: absolute;
            top: 50%;
            right: -3%;
          }
    }

`};


export default S;