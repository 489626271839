import React from 'react'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import styled from 'styled-components';
import TranslateStatic from '../../locales/TranslateStatic';
import { LocaleContext } from '../../locale-context';
const StyledPopup = styled(Popup)`
  // use your custom style for ".popup-overlay"
  &-overlay {
    ...;
  }
  // use your custom style for ".popup-content"
  &-content {
    .modal{
        padding:20px;
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:space-between;
        .close{
            // margin:40px;
            font-size:4em;
        }
    }
   
  }
`;
export default function PopupC(props) {
    return (<>
        <StyledPopup open={props.open} closeOnDocumentClick onClose={props.closeModal}>
            <div className="modal">
                <LocaleContext.Consumer>
                    {(value) => {
                        if (value === "en") {
                            return <div className='form'>
                                <span>Your request has been submitted. We will get back to you as soon as possible.</span>
                                <a className="close" onClick={props.closeModal}>
                                    &times;
                                </a>
                            </div>
                        } else if (value === "tr") {
                            return <div className='form'>
                                <span>Talebiniz iletilmiştir. En kısa sürede size geri dönüş sağlanacaktır.</span>
                                <a className="close" onClick={props.closeModal}>
                                    &times;
                                </a>
                            </div>
                        }
                    }}

                </LocaleContext.Consumer>
            </div>
        </StyledPopup>
        <StyledPopup open={props.openError} closeOnDocumentClick onClose={props.closeModalError}>
        <LocaleContext.Consumer>
                    {(value) => {
                        if (value === "en") {
                            return <div className="modal">

                            <span>Sorry, your request could not be fulfilled. You can contact us at info@gelecektupvebek.com.tr.</span>
                            <a className="close" onClick={props.closeModalError}>
                                &times;
                            </a>
                        </div>
                        } else if (value === "tr") {
                            return <div className="modal">

                            <span>Üzgünüz, talebiniz gerçekleştirilemedi. Bizimle info@gelecektupvebek.com.tr adresinden iletişime geçebilirsiniz.</span>
                            <a className="close" onClick={props.closeModalError}>
                                &times;
                            </a>
                        </div>
                        }
                    }}

                </LocaleContext.Consumer>
            
        </StyledPopup>
    </>
    )
}
