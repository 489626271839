import React from 'react';
import S from './style'
import Anne from '../../assets/anne.jpg'
import Hasta from '../../assets/hastaodakli.png'
import Ilgı from '../../assets/ilgi.png'
import Ulasilabilirlik from '../../assets/ulasilabilirlik.png'
import TranslateStatic from '../../locales/TranslateStatic'
function Whyus(props) {
    return (
        <S.Wrapper>
            <h4>
            <TranslateStatic string="nedenBiz" />
            </h4>
            {/* <p>Üreme tıbbındaki gelişmeleri yakından takip ederek, 
            yeni ve doğruluğu test edilmiş tedavileri kliniğimizde sizlere sunuyoruz.</p> */}
            {/* <p>
            <TranslateStatic string="nedenBizAciklama" />
            </p> */}
            <S.ImageWrapper>
                <div className='contain'>
                    <img src={Hasta} />
                    <h3><TranslateStatic string="nedenBizItem1" /></h3>
                    <p>
                    <TranslateStatic string="nedenBizItem1Aciklama" />
                    </p>
                </div>
                <div className='contain'>
                    <img src={Ulasilabilirlik} />
                    <h3><TranslateStatic string="nedenBizItem2" /></h3>
                    <p>
                    <TranslateStatic string="nedenBizItem2Aciklama" />
                    </p>
                </div>
                <div className='contain'>
                    <img src={Ilgı} />
                    <h3><TranslateStatic string="nedenBizItem3" /></h3>
                    <p>
                    <TranslateStatic string="nedenBizItem3Aciklama" />
                    </p>
                    </div>
            </S.ImageWrapper>
        </S.Wrapper>
    );
}

export default Whyus;