
import { Link } from 'react-router-dom';
import S from './style'
import TranslateStatic from '../../locales/TranslateStatic';
import AyseMG from '../../assets/ekip/aysekendirci.png'
export function Ayse() {
  return (
    <S.Wrapper>
      <S.Top>
        {/* <S.Header>
          <TranslateStatic string="aboutUsH2" />
        </S.Header> */}
        <S.Span>
          <Link to="./"><TranslateStatic string="home" /></Link> > <TranslateStatic string="teamHeader" />
        </S.Span>
      </S.Top>
      {/* <S.Sertifika>
        <Certification />
      </S.Sertifika> */}
      <S.Section>
        <S.Izgara>
          <div className='rowi'>
            <div className='items'>
              <img src={AyseMG}></img>
              <h2>Dr. Ayşe Kendirci Çeviren</h2>
              <div className='content'>
                <p><TranslateStatic string="Ayse" /></p>
                {/* <article>Kadın Hastalıkları ve Doğum Uzmanı</article> */}
                {/* <article>Endoskopi Uzmanı ( Bachelor in Endoscopy )</article> */}

              </div>

            </div>
          </div>
        </S.Izgara>
        {/* <div className='ozgecmis'>
          <p>Dr. Batu Aydınuraz 6 Haziran 1973 tarihinde mikrobiyolog bir anne ve fizik profesörü bir babanın ikinci çocuğu olarak Ankara’da dünyaya geldi.</p>

          <p>İlk öğrenimini Alparslan İlkokulu’nda orta ve lise öğrenimini T.E.D. Ankara Koleji’nde tamamladı.1992 yılında,dahiliye uzmanı dedesi ve genel cerrahi uzmanı olan ablasının izinden giderek Ankara Üniversitesi Tıp Fakültesi’nde tıp eğitimine başladı ve 1998 yılında “Tıp Doktoru” olarak mezun oldu. Aynı yıl girdiği Tıpta Uzmanlık Sınavı sonrasında Ankara Üniversitesi Tıp Fakültesi Kadın Hastalıkları ve Doğum Anabilim Dalı’nda ihtisasa başladı.</p>
          <br />
          <p>2003 Haziran ayında uzmanlığını alan Dr.Aydınuraz, Haziran 2003 tarihinden Nisan 2010 tarihine kadar Prof.Dr.Hakan Şatıroğlu ile birlikte Gen-ART Tüp Bebek Merkezi’nde kadın hastalıkları ve doğum uzmanı ,HŞ Tüp Bebek ve MEDICANA International Ankara Hastanesi Tüp Bebek Merkezi’nde IVF sorumlu hekimi olarak çalışmıştır.Yine bu dönemde (2004-2005) Azerbaycan Bakü’de ülkenin ilk tüp bebek merkezinin kurulması ve ilk hastaların tedaviye alınmasında aktif görev almıştır.Mart 2010 ile Aralık 2015  tarihleri arasında kurucu ve sorumlu hekim olarak MEMORIAL Antalya Hastanesi Tüp Bebek Merkezi’nde üreme tıbbı ve infertilite alanında kendi ekibi ile çalışmalarına devam etmiştir.Ocak 2016 tarihinden bu yana Dr.Mete Işıkoğlu ile birlikte Özel ANTALYA GELECEK TÜP BEBEK MERKEZİ’nde üreme sağlığı ve tüp bebek alanındaki çalışmalarına devam etmektedir. </p>

          <p>Dr.Aydınuraz, Ecz.Öznur Aydınuraz ile evlidir;Defne ve Deren isimlerinde ikiz kız çocuk babasıdır.</p>
        </div> */}
        <div>
          <h3>Kişisel Bilgiler</h3>
          <ul>
            <li>1963 Adana doğumlu</li>
            <li>1985 yılında Ç.Ü. Fen-Edebiyat Fak. Biyoloji Bölümünden mezun oldu</li>
            <li>1989 yılında Ç.Ü. Tıp Fakültesi bünyesinde ABD'de Fizyoloji(Farede Perifertilizasyon Atlası ve kullanılan Temel Yöntemler) alanında yüksek lisans eğitimini tamamladı</li>
            <li>1995 yılında Ç.Ü. Tıp Fakültesi bünyesinde ABD'de Fizyoloji(Erkek infertilitesinin Değerlendirimesi) alanında doktora eğitimini tamamladı</li>
          </ul>
          <h3>Bilimsel Eğitim</h3>
          <ul>
            <li>Mikroelektrod ile Hücre İçi Kayıt Yöntemleri . Yaz Kursu, Ç.Ü. Tıp Fakültesi Biyofizik ABD. ADANA (1-16 Eylül  1988)</li>
            <li>İn Vitro Fertilizasyon-Embriyo Transferi ve Embriyo Mühendisliği Kursu Ç.Ü.Tıp Fakültesi Fizyoloji ABD, ADANA (18-28 Eylül 1988)</li>
            <li>EIA ve RIA Tekniklerinin Hayvancılıkta Kullanılması Kursu. Türkiye Atom Enerjisi Kurumu, Lalahan Hayvan Sağlığı Nükleer Araştırma Enstitüsü, ANKARA (18-22 Aralık1989)</li>
            <li>Hücre Yapısına Giriş ve Bazı Zar Fonkfsiyonları. Sonbahar Okulu Türk Tabibleri Birliği,Tıpta Temel Bilimler Kolu, Alanya/ANTALYA. (12-15 Kasım1992)</li>
            <li>IVF te sperm ve yumurta hazırlama ve maniplasyonları. Ç.Ü. Tıp Fakültesi Kadın Hastalıkları ve Doğum ABD,  Tüp Bebek Ünitesi ADANA (01.02.1993-30.12.1994)</li>
            <li>Mikromanipulasyon ve Yumurtaya Sperm Enjeksiyonu. Yaz Kursu Ç.Ü.Tıp Fakültesi Fizyoloji ABD, (15 Haziran-15 Temmuz1995)</li>
            <li>21.Ulusal Fizyoloji Kongresi Türk Fizyoloji Bilimler Derneği, Ankara Üniversitesi Tıp Fakültesi (24-28 Eylül 1995)</li>
            <li>Çukurova Bölgesi 1.Üreme Endokrinolojisi ve İnfertilitede Yenilikler Simpozyumu.Ç.Ü.Tıp Fakültesi Kadın Hastalıkları ve Doğum ABD, ADANA 20-25 Kasım 1995)</li>
            <li>IVF-ET Teknikleri ve Mikromanüplasyon Teknikleri Eğitimi. Haddassah Medikal Organization Mount Scopus, Jerusalem ISRAEL (06.07.1996-06.09.1996)</li>
            <li>22.Ulusal Fizyoloji Kongresi Türk Fizyoloji Bilimler Derneği, Uludağ Üniversitesi Tıp Fakültesi BURSA (8-12  Eylül 1996)</li>
            <li>İnfertilitede Son Gelişmeler. Mezuniyet Sonrası Eğitim Kursu. İ.Ü.Cerrahpaşa Tıp Fakültesi Kadın Hastalıkları ve Doğum ABD, s. Bartholomen ve Kraliyet Londra Tıp Okulu, İSTANBUL (11-13 Ekim 1996)</li>
            <li>23.Ulusal Fizyoloji Kongresi. Türk Fizyoloji Bilimler Derneği Çukurova Üniversitesi.Tıp fakültei  ADANA  (29Eylül-4 Ekim 1997)</li>
            <li>Embriyo Freezing, Embriyo Thawing ve ICSI Teknikleri Eğitimi Haddassah Medikal Organization Mount Scopus, Jerusalem ISRAEL (03.07.1998-31.09.1998)</li>
            <li>Embriyo Freezing workshop Bourn Hall Clinic ve Sereno İSTANBUL (01-06 Nisan 2000)</li>
            <li>1.Reproductive Medicine Kongresi İSTANBUL (07-10 Haziran 2000 )</li>
            <li>2.İntarnasyonel  Üreme  ve Ovulasyon İndiüksiyonu Kongresi ANTALYA (03-06 Mayıs 2001)</li>
            <li>Preimplantasyon Genetik Diagnosis Workshop. European Society of Human  Reproduction  And Embryology Brussels free Üniversite Tıp Fak. BRÜKSEL (19-20 Eylül 2002)</li>
            <li>Yardımla Üreme Tekniklerinde Son Gelişmeler NewYork-Presbyterian The University Hospital of Colombia and Cornell İSTANBUL  (1-2 Ekim 2002)</li>
            <li>Uluslararası Güncel İnfertilite ve Yardımcı Üreme Teknikleri Sempozyumu Çeşme/İZMİR  (24-27 Nisan 2003)</li>
            <li>5.Uluslararası Preimplantasyon Genetik Tanı Sempozyumu ANTALYA (5-7 haziran 2003)</li>
            <li>İnternasyonal workshop .Gamet ve embriyo maniplasyon teknikleri Memorial Hospital ART ve üreme genetik merkezi İSTANBUL ( 02-07 Eylül 2003)</li>
            <li>European society of Human Reproduction and Embryology Kongresi Berlin ALMANYA (Haziran 2004)</li>
            <li>1.İnternasyonal Üreme Kongresi İİSTANBUL (22-25 Eylül 2004)</li>
            <li>In Vitro Fertilizasyonda  Kullanılan Medyumlar ve Yeni Teknolojik Gelişmeler Colarado Danver AMERİKA (18-27 Eylül2005)</li>
            <li>2.Güncel Üreme Endokrinolojisi, Yardıkla Üreme Teknikleri Kongresi ve 1.Üreme Tıbbı Derneği Kongresi Çeşme/İZMİR (17-20 Nisan 2008)</li>
          </ul>
        </div>
      </S.Section>
    </S.Wrapper >
  );
}
