import styled from "styled-components";

const S = {
    Wrapper:styled.div`
    display:flex;
    flex-direction:row;
    padding:100px 200px 50px 200px;
    justify-content:space-evenly;
    flex-wrap:wrap;
    @media (max-width:648px){
        padding:50px 10px;
        flex-direction:column;
        align-items:center;
    }
    `,
    Sol:styled.div`
        flex-basis:50%;
        display:flex;
        flex-direction:column;
        justify-content:space-evenly;
        @media (max-width:648px){
            flex-basis:100%;

        }
        h1{
            font-size:3em;
            color:rgb(212, 16, 49);
        }
        a{
            padding-right:20px;
            text-decoration:none;
            color:black;
            box-sizing:border-box;
            button{
                background:#01ff95;
                padding:10px 20px;
                color:black;
                border-radius:8px;
                border:0px solid;
                margin-top:20px;
                margin-bottom:20px;
                transition:300ms;
                font-size:1em;
                &:hover{
                    transition:300ms;
                    background:black;
                    color:white;
                    cursor:pointer;
                }
            }
            p{
            font-size:2.9em;
            font-weight:900;
            transition:300ms;
            @media (max-width:648px){
                font-size:2em;
    
            }
            b{
                background: -webkit-linear-gradient(45deg, #00A9C4, #00ff95 80%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            }
            &:hover{
                p{
                    transition:300ms;
                    font-size:3em;
                    @media (max-width:648px){
                        font-size:2.1em;
            
                    }
                    b{

                    }
                }
            }
        }
    }
    `,
    Sag:styled.div`
        flex-basis:50%;
        display:flex;
        flex-direction:column;
        justify-content:space-evenly;
       img{
        width:100%;
        border-radius:20px
       }
       @media (max-width:648px){
        flex-basis:100%;
    }
    `,

  
}

export default S;