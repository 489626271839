import styled from "styled-components";

const S = {
    Wrapper: styled.div`
        display:flex;
        position:fixed;
        flex-direction: column;
        justify-content:center;
        align-content:center;
        width:100%;
        height:100vh;
    `
}

export default S;