
import { Link } from 'react-router-dom';
import S from './style'
import TranslateStatic from '../../locales/TranslateStatic';
import { LocaleContext } from '../../locale-context';
import AzimeIMG from '../../assets/ekip/azime-evci.png'
export function Azime() {
  return (
    <S.Wrapper>
      <S.Top>
        {/* <S.Header>
          <TranslateStatic string="aboutUsH2" />
        </S.Header> */}
        <S.Span>
          <Link to="./"><TranslateStatic string="home" /></Link> > <TranslateStatic string="teamHeader" />
        </S.Span>
      </S.Top>
      {/* <S.Sertifika>
        <Certification />
      </S.Sertifika> */}
      <S.Section>
        <S.Izgara>
          <div className='rowi'>
            <div className='items'>
              <img src={AzimeIMG}></img>
              <h2><TranslateStatic string="AzimeUnvan" /> Dr. Azime Evci</h2>
              <div className='content'>
                <p><TranslateStatic string="Azime" /></p>
                {/* <article>Kadın Hastalıkları ve Doğum Uzmanı</article> */}
                {/* <article>Endoskopi Uzmanı ( Bachelor in Endoscopy )</article> */}

              </div>

            </div>
          </div>
        </S.Izgara>
        {/* <div className='ozgecmis'>
          <p>Dr. Batu Aydınuraz 6 Haziran 1973 tarihinde mikrobiyolog bir anne ve fizik profesörü bir babanın ikinci çocuğu olarak Ankara’da dünyaya geldi.</p>

          <p>İlk öğrenimini Alparslan İlkokulu’nda orta ve lise öğrenimini T.E.D. Ankara Koleji’nde tamamladı.1992 yılında,dahiliye uzmanı dedesi ve genel cerrahi uzmanı olan ablasının izinden giderek Ankara Üniversitesi Tıp Fakültesi’nde tıp eğitimine başladı ve 1998 yılında “Tıp Doktoru” olarak mezun oldu. Aynı yıl girdiği Tıpta Uzmanlık Sınavı sonrasında Ankara Üniversitesi Tıp Fakültesi Kadın Hastalıkları ve Doğum Anabilim Dalı’nda ihtisasa başladı.</p>
          <br />
          <p>2003 Haziran ayında uzmanlığını alan Dr.Aydınuraz, Haziran 2003 tarihinden Nisan 2010 tarihine kadar Prof.Dr.Hakan Şatıroğlu ile birlikte Gen-ART Tüp Bebek Merkezi’nde kadın hastalıkları ve doğum uzmanı ,HŞ Tüp Bebek ve MEDICANA International Ankara Hastanesi Tüp Bebek Merkezi’nde IVF sorumlu hekimi olarak çalışmıştır.Yine bu dönemde (2004-2005) Azerbaycan Bakü’de ülkenin ilk tüp bebek merkezinin kurulması ve ilk hastaların tedaviye alınmasında aktif görev almıştır.Mart 2010 ile Aralık 2015  tarihleri arasında kurucu ve sorumlu hekim olarak MEMORIAL Antalya Hastanesi Tüp Bebek Merkezi’nde üreme tıbbı ve infertilite alanında kendi ekibi ile çalışmalarına devam etmiştir.Ocak 2016 tarihinden bu yana Dr.Mete Işıkoğlu ile birlikte Özel ANTALYA GELECEK TÜP BEBEK MERKEZİ’nde üreme sağlığı ve tüp bebek alanındaki çalışmalarına devam etmektedir. </p>

          <p>Dr.Aydınuraz, Ecz.Öznur Aydınuraz ile evlidir;Defne ve Deren isimlerinde ikiz kız çocuk babasıdır.</p>
        </div> */}
        <LocaleContext.Consumer>
          {(value) => {
            if (value === "tr") {
              return <div>
                <h3>Kişisel Bilgiler</h3>

                <p>Dr Azime Evci 1969 yılında Isparta’da dogdu.
                  İlk ve orta öğrenimini Gönen/Guneykent ilköğretim okulunda tamamladı.
                  1983-1986 yılları arasinda Nevsehir Kız Öğretmen Lisesinde okudu.
                  1986 yılında Cumhuriyet Üniversitesi Tıp Fakultesinde başladığı tıp eğitimini, 1989
                  yılından itibaren Akdeniz Üniversitesi Tıp Fakultesinde sürdürerek 1992 yılında mezun
                  oldu.</p>
                <p>Mezuniyet sonrasında Giresun, Isparta ve Bolu / Gerede’de çalıştı.
                  1998-2003 yılları arasında Akdeniz Üniversitesi Anesteziyoloji ve Reanimasyon Anabilim
                  Dalında uzmanlık eğitimini tamamladı.</p>
                Uzman olduktan sonra Antalya Anadolu Hastanesi ve Alanya Egitim Araştırma
                Hastanesinde görev yaptı.
                Halen Gelecek Tüp Bebek Merkezinde anestezi uzmanı olarak çalışmaktadır.


              </div>

            } else if (value === 'en') {
              return <div>
                <h3>Personal Information</h3>
               
                  <p>Dr.Evci was born in Isparta in 1969. She completed her primary and secondary education at Guneykent,Isparta.
                    She graduated from high school in Nevşehir in 1986.
                    She started her medical education in 1986 at Cumhuriyet University in Sivas and moved to Antalya where she graduted from Akdeniz University in 1992.</p>
                    <p>After graduation she worked in Giresun,Isparta and Bolu before
                  specialization.</p>
                  She completed her specialization in the Department of Anesthesiology and
                  Reanimation between 1998 and 2003 at Akdeniz Universty.
                  Then she worked at Antalya Anatolia Hospital and,Alanya Training and Research Hospitals. She is currently working in invitro fertilization center.(Future-Gelecek).

                

              </div>
            }
          }}
        </LocaleContext.Consumer>

      </S.Section>
    </S.Wrapper >
  );
}
