import React, { useState } from 'react';
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
// import katalog from '../../assets/katolog.pdf'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
function MyApp() {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();


    return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
        {/* <Viewer
            fileUrl={}
            plugins={[
                // Register plugins
                defaultLayoutPluginInstance,
                
            ]}
        /> */}
        </Worker>
    );
}
export default MyApp