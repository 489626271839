
import { Link } from 'react-router-dom';
import S from './style'

import data from '../../services/data';
import dataen from '../../services/dataen';
import { LocaleContext } from '../../locale-context';
import TranslateStatic from '../../locales/TranslateStatic'
import { Banner } from './banner';
import Mosaic from './mosaic'
export function Category() {
  return (
    <S.Wrapper>
     
      <Banner/>
      {/* <Mosaic/> */}
      <S.Filter>
        <h2><TranslateStatic string="TümBilimselToplantı" /></h2>
      </S.Filter>
      <S.Section>
        <LocaleContext.Consumer>
          {(value) => {
            if (value === "en") {
              return dataen.map((x, i) => {
                return <Link to={x.category.link}>
                  <div className="item-wrapper">
                    <div className="item-image">
                      <img
                        src={x.category.imgsrc}
                        // src={location.origin + x.category.imgsrc}
                        alt="" />
                    </div>
                    <article>
                      <div>
                        <h2>{x.category.name}</h2>
                        {/* <p>{x.category.content}</p> */}
                      </div>
                      {/* <div>
                      <TranslateStatic string="categoryDetailButton" /> <ArrowCircleRight />
                    </div> */}
                    </article>
                  </div>
                </Link>
              })

            } else if (value === "tr") {
              return data.map((x, i) => {
                return <Link to={x.category.link}>
                  <div className="item-wrapper">
                    <div className="item-image">
                      <img
                        src={x.category.imgsrc}
                        // src={location.origin + x.category.imgsrc}
                        alt="" />
                    </div>
                    <article>
                      <div>
                        <h2>{x.category.name}</h2>
                        {/* <p>{x.category.content}</p> */}
                      </div>
                      {/* <div>
                      <TranslateStatic string="categoryDetailButton" /> <ArrowCircleRight />
                    </div> */}
                    </article>
                  </div>
                </Link>
              })
            }
          }}
        </LocaleContext.Consumer>
       
      </S.Section>
    </S.Wrapper>
  );
}
