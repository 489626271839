import React, { useState } from "react";
import { LocaleContext } from '../../../locale-context';
import TranslateStatic from '../../../locales/TranslateStatic'
const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        <p className="text">
            {isReadMore ? text.slice(0, 162) : text}
            <span
                onClick={toggleReadMore}
                className="read-or-hide"
                style={{ color: "#01aecb", cursor: "pointer" }}
            >
                {isReadMore ?  <TranslateStatic string="Devami" /> :  <TranslateStatic string="Daralt" />}
            </span>
        </p>
    );
};

const Content = () => {
    return (<LocaleContext.Consumer>
        {(value) => {
            if (value === "tr") {
                return <div className="container">
                    {/* <h2> */}
                    <ReadMore>
                        On yıllarca süren bilimsel çaba ve emeğin sonucu 1978 yılında üreme
                        alanında bir dönüm noktası gerçekleşti ve 'ilk tüp bebek' İngiltere’de
                        dünyaya gözlerini açtı.

                        Zaman içinde tüp bebek teknolojisinde yaşanan gelişmeler başarı
                        şansını arttırırken, doğal yolla gebelik şansı olmayan çiftlerin ebeveyn olma
                        hayallerinin gerçekleşmesinin yanısıra; genetik hastalık veya kromozom
                        anormallikleri olan ailelerde genetik testlerle söz konusu hastalıktan muaf
                        olan embryoların seçilmesi, henüz gebelik düşünmeyen bireylerde üreme
                        hücrelerinin tıbbi veya sosyal amaçla dondurularak saklanması gibi pek çok
                        yenilikler de eklendi.

                        Hız kesmeyen bilimsel araştırma ve gelişmelerle dünya genelinde her
                        yıl on binlerce çift “yardımcı üreme teknikleri” olarak da adlandırılan tüp
                        bebek tedavisiyle hayallerine kavuşmaya devam etmektedir.

                        Bilim ve teknolojiyi yakından takip eden ve katkı sağlayan ekibimizle
                        kendinizi güvende hissedeceksiniz.
                    </ReadMore>

                    {/* </h2> */}
                </div>
            } else if (value === 'en') {
                return <div className="container">
                    {/* <h2> */}
                    <ReadMore>
                        The result within the reproductive field after decades of scientific work and effort was,
                        a turning point taking place in 1978, when the
                        birth of the 'first IVF baby' was reported in England.
                        With the advancements in IVF technology over time increasing the chances of
                        success within the field, the dreams of couples without the chance of natural
                        pregnancy are coming true. Moreover, many innovations have also been added,
                        such as the selection of embryos free from genetic diseases or chromosomal
                        abnormalities through genetic testing in families with such conditions, and the storage
                        of sperm and egg cells for medical or social purposes in individuals who are not yet
                        considering pregnancy. With unabated scientific research and developments
                        continuing, every year, tens of thousands of couples worldwide achieve their dreams
                        with IVF treatment (also referred to as "assisted reproductive techniques").
                        With our team staying up to date actively contributing to advancements in
                        science and technology, you can rest assured in our care.
                    </ReadMore>

                    {/* </h2> */}
                </div>
            }
        }}
    </LocaleContext.Consumer>
    );
};

export default Content;