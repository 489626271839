import styled from 'styled-components';
import TR from '../../../assets/turkeyflag.png'
export const LangChangerSC = styled.select`
          border: none;
         margin:0px;
         padding:0px;
         background: #ffffff00;
         font-size:14px;
         
          background:white;
          padding:10px;
          border-radius:40px;
          transition:200ms;
          a{
              color:black;
          }
          // &:hover{
          //     background :#00A9C4;
          //     transition:200ms;
          //     a{
          //         color:white;
          //     }
          // }
      
         option{
          // background:${props => props.imageurl};
          font-size:10px;
         }
        //  img{
        //   width:20px;
        //   height:20px;
        //  }
        @media screen and (max-width:1000px){
       /* display:none; */
      }
        cursor:pointer;
          option {
  
            /* padding: 10px; */
          }
          .options {
            /* padding: 10px; */
          }
          &:hover {
            border: none;
          }
          &:focus {
            border: none;
            outline: none;
          }
`
export const OptionV = styled.option`
background-image:url(${TR});
// background-image:url("https://img.icons8.com/?size=100&id=15541&format=png&color=000000");
font-size:20px;
`