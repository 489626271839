import styled from "styled-components";

const S = {
    Wrapper: styled.div`
    display:${props => props.open ? "flex" : "none"};
    flex-direction:column;
    justify-content:center;
    align-content:center;
    position:fixed;
    z-index:2000000;
    width:100%;
    height:100vh;
    align-items:center;
    background-color:rgba(0, 0, 0, 0.5);
    .container{
        border-radius:20px;
        background-color:white;
        padding:40px;
        display:flex;
        flex-direction:column;
        align-items:center;
        gap:20px;
        h1{
            text-align:center;
        }
        span{
            cursor:pointer;
            width:85px;
            height:45px;
            display:flex;
            flex-direction:column;
            justify-content:center;
            align-items:center;
            img{
                width:40px;
                transition:300ms;
                &:hover{
                    width:45px;
                }
            }
        }
        img{
            width:100px;
        }
    }
    `
}

export default S;