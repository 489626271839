import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Tabs from './tabs'
import TranslateStatic from '../../../locales/TranslateStatic';
import S from '../style';
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion expanded={expanded === 'panel0'} onChange={handleChange('panel0')}>
        <AccordionSummary aria-controls="panel0d-content" id="panel0d-header">
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
            <TranslateStatic string="SSS0" />
          </Typography>
          {/* <Typography sx={{ color: 'text.secondary' }}><TranslateStatic string="SSS1A"/></Typography> */}
        </AccordionSummary>
        {/* <Tabs.Otels/> */}
        <AccordionDetails>
          <ul>
            <li>
              <TranslateStatic string="SSS0A-1" />
            </li>
            <li>
              <TranslateStatic string="SSS0A-2" />
            </li>
            <li>
              <TranslateStatic string="SSS0A-3" />
            </li>
            <li>
              <TranslateStatic string="SSS0A-4" />
            </li>
            <li>
              <TranslateStatic string="SSS0A-5" />
            </li>
            <li>
              <TranslateStatic string="SSS0A-6" />
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
            <TranslateStatic string="SSS1" />
          </Typography>
          {/* <Typography sx={{ color: 'text.secondary' }}><TranslateStatic string="SSS1A"/></Typography> */}
        </AccordionSummary>
        {/* <Tabs.Otels/> */}
        <AccordionDetails><TranslateStatic string="SSS1A" /></AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
            <TranslateStatic string="SSS2" />
          </Typography>
          {/* <Typography sx={{ color: 'text.secondary' }}><TranslateStatic string="SSS1A"/></Typography> */}
        </AccordionSummary>
        {/* <Tabs.Otels/> */}
        <AccordionDetails><TranslateStatic string="SSS2A" /></AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
            <TranslateStatic string="SSS3" />
          </Typography>
          {/* <Typography sx={{ color: 'text.secondary' }}><TranslateStatic string="SSS1A"/></Typography> */}
        </AccordionSummary>
        {/* <Tabs.Otels/> */}
        <AccordionDetails><TranslateStatic string="SSS3A" /></AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
            <TranslateStatic string="SSS4" />
          </Typography>
          {/* <Typography sx={{ color: 'text.secondary' }}><TranslateStatic string="SSS1A"/></Typography> */}
        </AccordionSummary>
        {/* <Tabs.Otels/> */}
        <AccordionDetails><TranslateStatic string="SSS4A" /></AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
            <TranslateStatic string="SSS5" />
          </Typography>
          {/* <Typography sx={{ color: 'text.secondary' }}><TranslateStatic string="SSS1A"/></Typography> */}
        </AccordionSummary>
        {/* <Tabs.Otels/> */}
        <AccordionDetails><TranslateStatic string="SSS5A" /></AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
            <TranslateStatic string="SSS6" />
          </Typography>
          {/* <Typography sx={{ color: 'text.secondary' }}><TranslateStatic string="SSS1A"/></Typography> */}
        </AccordionSummary>
        {/* <Tabs.Otels/> */}
        <AccordionDetails><TranslateStatic string="SSS6A" /></AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
        <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
            <TranslateStatic string="SSS7" />
          </Typography>
          {/* <Typography sx={{ color: 'text.secondary' }}><TranslateStatic string="SSS1A"/></Typography> */}
        </AccordionSummary>
        {/* <Tabs.Otels/> */}
        <AccordionDetails><TranslateStatic string="SSS7A" /></AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
        <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
            <TranslateStatic string="SSS8" />
          </Typography>
          {/* <Typography sx={{ color: 'text.secondary' }}><TranslateStatic string="SSS1A"/></Typography> */}
        </AccordionSummary>
        {/* <Tabs.Otels/> */}
        <AccordionDetails><TranslateStatic string="SSS8A" /></AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
        <AccordionSummary aria-controls="panel9d-content" id="panel9d-header">
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
            <TranslateStatic string="SSS9" />
          </Typography>
          {/* <Typography sx={{ color: 'text.secondary' }}><TranslateStatic string="SSS1A"/></Typography> */}
        </AccordionSummary>
        {/* <Tabs.Otels/> */}
        <AccordionDetails><TranslateStatic string="SSS9A" /></AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
        <AccordionSummary aria-controls="panel10d-content" id="panel10d-header">
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
            <TranslateStatic string="SSS10" />
          </Typography>
          {/* <Typography sx={{ color: 'text.secondary' }}><TranslateStatic string="SSS1A"/></Typography> */}
        </AccordionSummary>
        {/* <Tabs.Otels/> */}
        <AccordionDetails><TranslateStatic string="SSS10A" /></AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
        <AccordionSummary aria-controls="panel11d-content" id="panel11d-header">
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
            <TranslateStatic string="SSS11" />
          </Typography>
          {/* <Typography sx={{ color: 'text.secondary' }}><TranslateStatic string="SSS1A"/></Typography> */}
        </AccordionSummary>
        {/* <Tabs.Otels/> */}
        <AccordionDetails><TranslateStatic string="SSS11A" /></AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
        <AccordionSummary aria-controls="panel12d-content" id="panel12d-header">
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
            <TranslateStatic string="SSS12" />
          </Typography>
          {/* <Typography sx={{ color: 'text.secondary' }}><TranslateStatic string="SSS1A"/></Typography> */}
        </AccordionSummary>
        {/* <Tabs.Otels/> */}
        <AccordionDetails><TranslateStatic string="SSS12A" /></AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
        <AccordionSummary aria-controls="panel13d-content" id="panel13d-header">
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
            <TranslateStatic string="SSS13" />
          </Typography>
          {/* <Typography sx={{ color: 'text.secondary' }}><TranslateStatic string="SSS1A"/></Typography> */}
        </AccordionSummary>
        {/* <Tabs.Otels/> */}
        <AccordionDetails><TranslateStatic string="SSS13A" /></AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel14'} onChange={handleChange('panel14')}>
        <AccordionSummary aria-controls="panel14d-content" id="panel14d-header">
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
            <TranslateStatic string="SSS14" />
          </Typography>
          {/* <Typography sx={{ color: 'text.secondary' }}><TranslateStatic string="SSS1A"/></Typography> */}
        </AccordionSummary>
        {/* <Tabs.Otels/> */}
        <AccordionDetails><TranslateStatic string="SSS14A" /></AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel15'} onChange={handleChange('panel15')}>
        <AccordionSummary aria-controls="panel15d-content" id="panel15d-header">
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
            <TranslateStatic string="SSS15" />
          </Typography>
          {/* <Typography sx={{ color: 'text.secondary' }}><TranslateStatic string="SSS1A"/></Typography> */}
        </AccordionSummary>
        {/* <Tabs.Otels/> */}
        <AccordionDetails><TranslateStatic string="SSS15A" /></AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel16'} onChange={handleChange('panel16')}>
        <AccordionSummary aria-controls="panel16d-content" id="panel16d-header">
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
            <TranslateStatic string="SSS16" />
          </Typography>
          {/* <Typography sx={{ color: 'text.secondary' }}><TranslateStatic string="SSS1A"/></Typography> */}
        </AccordionSummary>
        {/* <Tabs.Otels/> */}
        <AccordionDetails><TranslateStatic string="SSS16A" /></AccordionDetails>
      </Accordion>
    </div>
  );
}
