import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import S from './style'

import { DoubleArrow } from '@mui/icons-material'
import { useParams } from 'react-router-dom';
import data from '../../services/data';
import dataen from '../../services/dataen';
import { useEffect, useState } from 'react';
import { LocaleContext } from '../../locale-context';
import TranslateStatic from '../../locales/TranslateStatic';
import { Banner } from '../Category/banner';
export function SubCategory() {
  let { id, name } = useParams();
  const value = useContext(LocaleContext);
  console.log(value)
  const [result, setResult] = useState(false);
  useEffect(() => {
    if (value === "en") {
      let filterdata = dataen.find(element => element.category.id == id)
      setResult(filterdata)
      console.log(filterdata)

    } else if (value === "tr") {
      let filterdata = data.find(element => element.category.id == id)
      setResult(filterdata)
      console.log(filterdata)
    }
  }
    , [id, value])
  return (
    <S.Wrapper>
      <Banner/>
      <S.Section>
        {result && result.category.subcategories.map((x, i) => {
          if (!x.hide)
            return <div className="item-wrapper">
              {/* <div className="item-image">
                <img
                  src={x.imgsrc[0]}
                  // src={location.origin + x.category.imgsrc}
                  alt="" />
              </div> */}
              <article>
                <h2>{x.name}</h2>
                {x.link && <Link to={x.link}><TranslateStatic string="categoryDetailButton" /> <DoubleArrow /></Link>}
                
              </article>
            </div>
        })}
      </S.Section>
    </S.Wrapper>
  );
}
