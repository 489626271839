
import { Link } from 'react-router-dom';
import S from './style'
import TranslateStatic from '../../locales/TranslateStatic';
import BatuIMG from '../../assets/ekip/batu.jpg'
import { LocaleContext } from '../../locale-context';
export function Batu() {
  return (
    <S.Wrapper>
      <S.Top>
        {/* <S.Header>
          <TranslateStatic string="aboutUsH2" />
        </S.Header> */}
        <S.Span>
          <Link to="./"><TranslateStatic string="home" /></Link> > <TranslateStatic string="teamHeader" />
        </S.Span>
      </S.Top>
      {/* <S.Sertifika>
        <Certification />
      </S.Sertifika> */}
      <S.Section>
        <S.Izgara>
          <div className='rowi'>
            <div className='items'>
              <img src={BatuIMG}></img>
              <LocaleContext.Consumer>
                {(value) => {
                  if (value === "tr") {
                    return <h2>Op. Dr. Batu Aydınuraz</h2>
                  }
                  else if (value === "en") {
                    return <h2>Batu Aydinuraz, MD</h2>
                  }
                }}
              </LocaleContext.Consumer>

              <div className='content'>
                {/* <p>Mes'ul Müdür</p> */}
                <p><TranslateStatic string="BatuAydinuraz" /></p>
                {/* <article>Endoskopi Uzmanı ( Bachelor in Endoscopy )</article> */}

              </div>

            </div>
          </div>
        </S.Izgara>
        <LocaleContext.Consumer>
          {(value) => {
            if (value === "tr") {
              return <>
                <div className='ozgecmis'>
                  <p>Dr. Batu Aydınuraz 6 Haziran 1973 tarihinde mikrobiyolog bir anne ve fizik profesörü bir babanın ikinci çocuğu olarak Ankara’da dünyaya geldi.</p>

                  <p>İlk öğrenimini Alparslan İlkokulu’nda orta ve lise öğrenimini T.E.D. Ankara Koleji’nde tamamladı.1992 yılında,dahiliye uzmanı dedesi ve genel cerrahi uzmanı olan ablasının izinden giderek Ankara Üniversitesi Tıp Fakültesi’nde tıp eğitimine başladı ve 1998 yılında “Tıp Doktoru” olarak mezun oldu. Aynı yıl girdiği Tıpta Uzmanlık Sınavı sonrasında Ankara Üniversitesi Tıp Fakültesi Kadın Hastalıkları ve Doğum Anabilim Dalı’nda ihtisasa başladı.</p>
                  <br />
                  <p>2003 Haziran ayında uzmanlığını alan Dr.Aydınuraz, Haziran 2003 tarihinden Nisan 2010 tarihine kadar Prof.Dr.Hakan Şatıroğlu ile birlikte Gen-ART Tüp Bebek Merkezi’nde kadın hastalıkları ve doğum uzmanı ,HŞ Tüp Bebek ve MEDICANA International Ankara Hastanesi Tüp Bebek Merkezi’nde IVF sorumlu hekimi olarak çalışmıştır.Yine bu dönemde (2004-2005) Azerbaycan Bakü’de ülkenin ilk tüp bebek merkezinin kurulması ve ilk hastaların tedaviye alınmasında aktif görev almıştır.Mart 2010 ile Aralık 2015  tarihleri arasında kurucu ve sorumlu hekim olarak MEMORIAL Antalya Hastanesi Tüp Bebek Merkezi’nde üreme tıbbı ve infertilite alanında kendi ekibi ile çalışmalarına devam etmiştir.Ocak 2016 tarihinden bu yana Dr.Mete Işıkoğlu ile birlikte Özel ANTALYA GELECEK TÜP BEBEK MERKEZİ’nde üreme sağlığı ve tüp bebek alanındaki çalışmalarına devam etmektedir. </p>

                  <p>Dr.Aydınuraz, Ecz.Öznur Aydınuraz ile evlidir;Defne ve Deren isimlerinde ikiz kız çocuk babasıdır.</p>
                </div>
                <div>
                  <h3>Dernek Üyelikleri</h3>
                  <ul>
                    <li>TSRM (Üreme Sağlığı ve İnfertilite Derneği)</li>
                    <li>TJOD (Türk Jinekoloji ve Obstetri Derneği)</li>
                    <li>ÖZEL TÜP BEBEK MERKEZLERİ DERNEĞİ</li>
                    <li>ASRM (American Society for Reproductive Medicine) Üyeliği</li>
                    <li>ESHRE (European Society of Human Reproduction and Embryology) Üyeliği</li>
                    <li>T.E.D. Ankara Koleji Mezunlar Derneği Üyeliği</li>
                  </ul>
                  <h3>Yurtdışı Yayınlar (SCI kapsamındakiler)</h3>

                  <p>1.Baltaci V, Satiroglu H, Unsal E, Uner O, Ergun MA, Batioglu S, Sonmezer M, Kabukcu C, Aydinuraz B, Aktas Y. Preimplantation genetic diagnosis in two couples with balanced reciprocal translocations. Eur J Obstet Gynecol Reprod Biol. 2006 Jul 28 PMID: 16876931</p>
                  <p>2.Baltaci V, Satiroglu H, Kabukcu C, Unsal E, Aydinuraz B, Uner O, Aktas Y, Cetinkaya E, Turhan F, Aktan A. Relationship between embryo quality and aneuploidies. Reprod Biomed Online. 2006 Jan;12(1):77-82. PMID: 16454940</p>
                  <p>3.Tufan AC, Satiroglu-Tufan NL, Aydinuraz B, Satiroglu MH, Aydos K, Bagci H. No association of the CAG repeat length in exon 1 of the androgen receptor gene with idiopathic infertility in Turkish men: implications and literature review. Tohoku J Exp Med. 2005 Jun;206(2):105-15. Review. PMID: 15888966</p>
                  <p>4.Atabekoglu CS, Sonmezer M, Aydinuraz B, Dunder I. Extremely elevated CA 125 level due to an unruptured large endometrioma. Eur J Obstet Gynecol Reprod Biol. 2003 Sep 10;110(1):105-6. PMID: 12932883</p>
                  <p>5.Cok I, Donmez MK, Hakan Satiroglu M, Aydinuraz B, Henkelmann B, Kotalik J, Schramm KW, Concentration of polychlorinated dibenzo-p-dioxins, polychlorinated dibenzofurans and dioxin-like PCBs in human adipose tissue from Turkish men. Chemosphere. 2007 Jan;66(10):1955-1961. Epub 2006 Sep 8</p>
                  <p>6. Concentrations of polychlorinated dibenzo-p-dioxins (PCDDs), polychlorinated dibenzofurans (PCDFs), and dioxin-like PCBs in adipose tissue of infertile men. Cok I, Donmez MK, Satiroğlu MH, Aydinuraz B, Henkelmann B, Shen H, Kotalik J, Schramm KW. Arch Environ Contam Toxicol. 2008 Jul;55(1):143-52. Epub 2008 Jan 1</p>
                  <p>7. Uterine rupture at the 29th week of subsequent pregnancy after hysteroscopic resection of uterine septum. Satıroğlu MH, Gözüküçük M, Cetinkaya SE, Aydınuraz B, Kahraman K. Fertil Steril. 2008 Dec 3</p>
                  <p>8. Live twin birth after successful treatment of a ruptured heterotopic pregnancy by laparoscopy Murat Gözüküçük, Şerife Esra Çetinkaya, Batu Aydınuraz, Korhan Kahraman, Mustafa Hakan Şatıroğlu Gynecological Surgery 04/2012; 8(2):239-241</p>
                  <p>9. Clinical pregnancy after uterus transplantation Erman Akar M,Ozkan O,Aydinuraz B,Dirican K,Cincik M,Mendilcioglu I,Simsek M,Gunseren F,Kocak H,Ciftcioglu,Gecici O,Ozkan O. Fertil Steril. 2013 Nov;100(5):1358-1363</p>
                  <p>10.<a href="https://www.ncbi.nlm.nih.gov/pubmed/27638900">Uterus transplantation: From animal models through the first heart beating pregnancy to the first human live birth.</a>Ozkan O, Dogan NU, Ozkan O, Mendilcioglu I, Dogan S, Aydinuraz B, Simsek M.<span class="jrnl" title="Women's health (London, England)">Womens Health (Lond)</span>. 2016 Jul;12(4):442-9. doi: 10.1177/1745505716653849. Review.PMID:27638900</p>
                  <p>11.<a href="https://www.ncbi.nlm.nih.gov/pubmed/27734719">Artificial oocyte activation after intracytoplasmic morphologically selected sperm injection: A prospective randomized sibling oocyte study.</a>&nbsp;Aydinuraz B, Dirican EK, Olgan S, Aksunger O, Erturk OK.<span class="jrnl" title="Human fertility (Cambridge, England)">Hum Fertil (Camb)</span>. 2016 Dec;19(4):282-288.PMID:27734719</p>
                  <p>12.Conventional IVF revisited: Is ICSI better for non-male factor infertility? Randomized controlled
                    double blind study <a href="https://pubmed.ncbi.nlm.nih.gov/?term=Isikoglu+M&amp;cauthor_id=33221561">M Isikoglu</a>, <a href="https://pubmed.ncbi.nlm.nih.gov/?term=Avci+A&amp;cauthor_id=33221561">A Avci</a>, <a href="https://pubmed.ncbi.nlm.nih.gov/?term=Kendirci+Ceviren+A&amp;cauthor_id=33221561">A Kendirci Ceviren</a>, <a href="https://pubmed.ncbi.nlm.nih.gov/?term=Ayd%C4%B1nuraz+B&amp;cauthor_id=33221561">B Aydınuraz</a>, <a href="https://pubmed.ncbi.nlm.nih.gov/?term=Ata+B&amp;cauthor_id=33221561">B Ata</a> J Gynecol Obstet Hum Reprod. 2021 Sep;50(7):101990. <a href="https://linkinghub.elsevier.com/retrieve/pii/S2468784720303603">DOI: 10.1016/j.jogoh.2020.101990.</a></p>
                  <p>13.Comparison of ICSI and conventional IVF in non-male factor patients with less than four oocytes <a href="https://pubmed.ncbi.nlm.nih.gov/?term=Isikoglu+M&amp;cauthor_id=35224650">Mete Isikoglu</a>, <a href="https://pubmed.ncbi.nlm.nih.gov/35224650/">Ayse Kendirci Ceviren</a>, <a href="https://pubmed.ncbi.nlm.nih.gov/?term=Cetin+T&amp;cauthor_id=35224650">Tugba Cetin</a>, <a href="https://pubmed.ncbi.nlm.nih.gov/?term=Avci+A&amp;cauthor_id=35224650">Aysenur Avci</a>, <a href="https://pubmed.ncbi.nlm.nih.gov/?term=Aydinuraz+B&amp;cauthor_id=35224650">Batu Aydınuraz</a>, <a href="https://pubmed.ncbi.nlm.nih.gov/?term=Akgul+OK&amp;cauthor_id=35224650">Ozlem Karabay AkguL</a>, <a href="https://pubmed.ncbi.nlm.nih.gov/?term=Karaca+M&amp;cauthor_id=35224650">Mehmet Karaca</a> Arch Gynecol Obstet 2022 Aug;306(2):493-499.
                    DOI: 10.1007/s00404-022-06471-x.
                  </p>

                  <h3>Yurtiçi Yayınlar</h3>

                  <p>1. ILTEMİR, Candan; CENGİZ, Bora; AYDINURAZ, Batu; SÖYLEMEZ, Feride Eosinophilic granuloma and pregnancy Gynecology Obstetrics and Reproduction Medicine, 2004,10(1):66-67</p>
                  <p>2.SÖNMEZER, Murat; ATABEKOĞLU, Cem; BERKER, Bülent; KABUKÇU, Cihan; AYDINURAZ, Batu; ŞATIROĞLU, Hakan Hysteroscopic treatment of uterine septum: Reproductive outcome Gynecology Obstetrics and Reproduction Medicine, 2001,7(2):106-108</p>
                  <p>3.M. Ziya GÜNENÇ, Banu BİNGÖL, Selçuk TABAK, Batu AYDİNURAZ Peritoneal Tuberculosis with Elevated Ca 125 Levels, a Diagnostic Challenge and Value of Laparoscopy and ADA Level in the Differential Diagnosis Gynecol Obstet Reprod Med,GORM, 2005; 11:215-217</p>
                  <p>4. Cinsel Yolla Bulaşan Hastalıklar Ve Pelvik Inflamatuvar Hastalıkta Güncel Bilgi Ve Yaklaşımlar Dr. Batu AYDINURAZa,Dr. Cihan KABUKÇUa,Dr. M. Hakan ŞATIROĞLUb Turkiye Klinikleri J Gynecol Obst-Special Topics 2008;1(5):57-66</p>
                  <p>5. Infertil Çiftte Kadının Değerlendirilmesi Ve Tedavinin Düzenlenmesi Dr. Batu AYDINURAZ,Dr. Cihan KABUKÇUa,Dr. M. Hakan ŞATIROĞLU Turkiye Klinikleri J Urology-Special Topics 2008, 1(1):8-16</p>

                  <h3>Poster Sunumları:</h3>


                  <p>AA-10th Biennial Conference of Alpha, Scientists in Reproductive Medicine, 9–11 May 2014, Antalya, Turkey Fertilization and embryo quality with and without artificial oocyte activation in teratozoospermia after intracytoplasmic morphologically selected sperm injection: a prospective randomized sibling-oocyte study Enver Kerem Dirican, Ozlem Aksunger, Leman Rasa Erel, Batu Aydinuraz &nbsp; &nbsp; &nbsp;&nbsp; Memorial Hospital of Antalya RBM Online May 2014Volume 28, Supplement 1, Pages&nbsp;S13–S14</p>
                  <p>AB- PGDIS 2013 Congress 12 th International PGD Conference, Istanbul, 8-11 May 2013 Healthy ongoing pregnancy achieved by Preimplantation Genetic Diagnosis using array comparative genomic hybridization in a couple with reciprocal translocation carrier female and male with Klinefelter Syndrome: Case report E.K. Dirican, C. Beyazyurek, C.C. Yapan, O. Aksunger, Z. Salar, B. Aydinuraz, C.G. Ekmekci. Memorial Hospital of Antalya, Antalya, Turkey, 2Memorial Hospital, Istanbul, Turkey RBM Online 26 Suppl. 1 (2013) S1–S11</p>
                  <p>AC-Sixth International Symposium on Preimplantation Genetics (19-21 May 2005), London, UK</p>
                  <p>1.Relationship between embryo quality and aneuploidies Satiroglu H, Baltaci V, Kabukçu C, Ünsal E, Aydinuraz B, Üner Ö, Aktas Y, Çetinkaya E, Turhan F Gen ART Woman Health, IVF and Biotechnology Centre,Ankara Turkey RBM Online,Volume 10,suppl.2,2005</p>
                  <p>2.A simplified blastomere fixation technique Baltaci V*, Satiroglu H, Aydinuraz B, Unsal E, Uner O, Cetinkaya E, Kabukcu C, Aktas Y, Turhan F Gen ART Woman Health, IVF and Biotechnology Centre, Ankara, Turkey RBM Online,Volume 10,suppl.2,2005</p>
                  <p>AD-ASRM 2005 Philedelphia Trinucleotide (CAG) Repeat Length in Exon 1 of the Androgen Receptor Gene of Turkish Males with Idiopathic Infertility. A. C. Tufan, N. L. Satiroglu-Tufan, B. Aydinuraz, M. H. Satiroglu. Pamukkale University School of Medicine, Denizli, Turkey; Gen-Art Women Health, IVF and Reproductive Biotechnology, Ankara, Turkey; Ankara University School of Medicine, Department of Obstetrics and Gynecology, Ankara, Turkey. <span class="journalTitleSp">&nbsp;Fertility and Sterility</span>, <span class="issueVolSp">Vol. 82</span>, <span class="pageRange">S179 September 2004 </span></p>
                  <p>AE-European Human Genetics Conference 2006, Amsterdam, The Netherlands Preimplantation genetic diagnosis in two couples with balanced reciprocal translocation Baltaci V, Satiroglu H, Unsal E, Uner O, Ergun MA, Batioglu S, Sonmezer M, Kabukcu C, Aydinuraz B, Aktas Y Gen-Art Women Health, IVF and Reproductive Biotechnology, Ankara, Turkey; Ankara University School of Medicine, Department of Obstetrics and Gynecology,Ankara, Turkey. European Journal Of Human Genetics,Volume 14 Supplement 1,p.203 May 2006</p>
                  <p>AF-8th Internatonal Symposium on Pharmaceutical Sciences Ankara,TURKEY</p>
                  <p>1.Organochlorine Pesticide (OCP) and Polychlorinated Biphenyl (PCB) Levels in Adipose Tissue of Infertile Men T.Çalık Durmaz,M.H.Şatıroglu,B.Aydınuraz,C.Kabukçu,İ.Çok Gazi University,Faculty of Pharmacy,Department of Toxicology,Ankara,Turkey Ankara University School of Medicine,Department of Obstetrics and Gynecology,Ankara, Turkey Gen-Art Women Health, IVF and Reproductive Biotechnology, Ankara, Turkey <span><span class="style7">FABAD J. Pharm. Sci., 31(1), 7-14, 2006</span></span></p>
                  <p>2.Levels of Dioxin-like PCB Congeners in Adipose Tissue of Turkish Men M.Keski Dönmez, İ.Çok, M.H.Şatıroğlu, B.Aydınuraz, B.Henkelmann, J.Kotalik K.W.Schramm Gazi University,Faculty of Pharmacy, Department of Toxicology,Ankara,Turkey Ankara University School of Medicine,Department of Obstetrics and GynecologyAnkara, Turkey Gen-Art Women Health, IVF and Reproductive Biotechnology, Ankara, Turkey GSF-National Research Center for Environment and Health,Institute of Ecological Chemistry,Neuherberg,Germany <span ><span class="style7">FABAD J. Pharm. Sci., 31(1), 7-14, 2006</span></span></p>
                  <p>AG-V. Kongress der Deutsch-Türkischen Gynäkologengesellschaft II. Internationaler Kongress für Reproduktionsmedizin, May 2003, Antalya,Turkey</p>
                  <p>1.OP-014 PREMALİGN SERVİKS LEZYONLARINDA TELOMERAZ AKTİVİTESİNİN TANISAL DEĞERİ E.Şimşek, B. Aydınuraz, B. Özmen, A. Tükün, N. Kutlay, İ. Dünder, F. Ortaç</p>
                  <p>2. VP-003 VAGINAL CUFF PROLAPSUSUNDA WILLIAMS OPERASYONU DENEYİMİMİZ E.Şimşek, K. Kahraman, B. Aydınuraz, C. İltemir, C. Atabekoğlu, F. Ortaç</p>
                  <p>3.OP-038 NON-OBSTRÜKTİF AZOOSPERMİK İNFERTİLİTEDE TELOMERAZ HTERT KATALİTİK SUBÜNİTESİ, VİMENTİN VE SİTOKERATİN EKSPRESYONUNUN ICSI İÇİN GERM HÜCRE ELDE ETME YÖNÜNDE PREDİKTİF DEĞERİ B. AydInuraz, E.Şimşek, B. Özmen, L. Demirel1, K. Aydos, C. Ünlü,Ö. Tulunay</p>
                  <p>4.P-139 OVER KANSERİNDE ERKEN REKÜRRENSİN BELİRLENMESİNDE TELOMERAZ AKTİVİTESİNİN YERİ B. Özmen, F. Ortaç, A. Tükün, M. Güngör, B. Aydınuraz, E. Şimşek, T. Eroğlu Elmas, C. Atabekoğlu</p>
                  <p>5. P-146 NON-OBSTRÜKTİF AZOOSPERMİK İNFERTİLİTEDE SERUM FSH, LH, TOTAL TESTOSTERON DÜZEYİ VE TESTİS VOLÜMÜ İLE TESTİS DOKUSUNUN HİSTOPATOLOJİK MATÜRASYON BELİRTECİ OLAN JOHNSEN SKORUNUN ICSI İÇİN GERM HÜCRE ELDE ETME YÖNÜNDE PREDİKTİF DEĞERİ B. Aydınuraz, B. Özmen, E.Şimşek, L. Demirel, C.Ünlü, K. Aydos, .Ö.Tulunay</p>
                  <p>6.P-148 PREMALİGN SERVİKS LEZYONLARINDA SMEAR, YÖNLENDİRİLMİŞ BİYOPSİ VE NİHAİ PATOLOJİLERİN KARŞILAŞTIRILMASI E.Şimşek , B. Özmen, B. Aydınuraz, C. İltemir, K. Kahraman, T. Eroğlu Elmas, İ. D.nder, F. Ortaç</p>
                  <p>7. OP-016 OVER KANSER‹ SECOND-LOOK OPERASYONUNDA POZİTİF SECOND-LOOK VAKALARIN BELİRLENMESİNDE TELOMERAZ AKTİVİTESİNİN YERİ B. Özmen, F. Ortaç, A. Tükün, M. Güngör, G. Gümüş, E. Şimşek, B. Aydınuraz, T. Eroğlu Elmas, K. Kahraman</p>

                </div>
              </>
            }
            else if (value === "en") {
              return <>
                <div className='ozgecmis'>
                  <p>Born: June 6, 1973 | Location: Ankara, Turkey</p>
                  <p>Dr. Batu Aydınuraz was born on June 6, 1973 in Ankara as the second child of a microbiologist
                    mother and a physics professor father. He completed his primary education at Alparslan Primary
                    School and his secondary and high school education at T.E.D. Ankara College.In 1992, following in
                    the footsteps of his grandfather, who is an internal medicine specialist, and his sister, who is a
                    general surgery specialist, he started his medical education at Ankara University Faculty of Medicine
                    and graduated as a “Doctor of Medicine&quot; in 1998. After passing the Medical Specialization Exam in
                    the same year, he started his residency in Ankara University Faculty of Medicine, Department of
                    Obstetrics and Gynaecology.</p>
                  <p>
                    Dr. Aydınuraz, who received his degree in June 2003, worked as a
                    gynaecologist and obstetrician at Gen-ART IVF Center, HŞ IVF and IVF physician in charge at
                    MEDICANA International Ankara Hospital IVF Center with Prof. Dr. Hakan Şatıroğlu from June 2003
                    to April 2010. Also during this period (2004-2005), he took an active role in the establishment of the
                    country&#39;s first IVF center in Baku, Azerbaijan and the treatment of the first patients. Between March
                    2010 and December 2015, he continued to work with his own team in the field of reproductive
                    medicine and infertility at MEMORIAL Antalya Hospital IVF Center as the founder and physician in
                    charge.
                  </p>
                  <p>
                    Since January 2016, he has been working in the field of reproductive health and IVF with Dr.
                    Mete Işıkoğlu at Private ANTALYA GELECEK IVF CENTER. Dr. Aydınuraz is married to Pharmacist Oznur
                    Aydınuraz; He is the father of twin daughters named Defne and Deren.
                  </p>
                </div>
                <div>
                  <h3>Association Memberships</h3>
                  <ul>
                    <li>TSRM (Reproductive Health and Infertility Association)</li>
                    <li>TJOD (Turkish Society of Gynecology and Obstetrics)</li>
                    <li>ASSOCIATION OF PRIVATE IVF CENTERS (TBMD)</li>
                    <li>ASRM (American Society for Reproductive Medicine) Membership</li>
                    <li>ESHRE (European Society of Human Reproduction and Embryology) Membership</li>
                    <li>T.E.D. Ankara College Alumni Association Membership</li>
                  </ul>
                  <h3>OBJECTIVE:</h3>
                  <p>Extensively experienced Obstetrics &amp; Gynaecology and IVF specialist, committed to aiding infertile
                    couples, focusing on recurrent implantation failure and single-gene disorders. Equipped with
                    comprehensive experience in both minimally invasive and open surgery. Eager to leverage these
                    skills to enhance patient outcomes in a clinical setting.</p>
                  <h3>EDUCATION:</h3>
                  <p>Ankara University School of Medicine Department of Obstetrics and Gynaecology, Ankara, TURKEY
                    Residency in Obstetrics &amp; Gynaecology,1998 – 2003
                    Specialist in Obstetrics and Gynaecology</p>
                  <p>Ankara University School of Medicine, Ankara, TURKEY
                    Doctor of Medicine, 1998</p>
                  <p>TED Ankara College, Ankara, TURKEY
                    High School, 1991</p>
                  <p>Alpaslan Primary School, Ankara, TURKEY
                    1984</p>
                  <h3>PROFESSIONAL EXPERIENCE:</h3>
                  <p>Gelecek IVF Centre, Antalya, TURKEY
                    IVF Specialist, 2016 - Present</p>
                  <p>Memorial Hospital IVF Centre, Antalya
                    Clinical Director, 2010 - 2015</p>
                  <p>Medicana Hospital IVF Centre, Ankara
                    Clinical Director, 2008 - 2010</p>
                  <p>Republic of Turkey Ministry of Health
                    Commissioned IVF Specialist, 2005 - 2007
                    (Helped establishing Azerbaijan’s first IVF programme)</p>
                  <p>Gen-Art IVF Centre, Ankara
                    Obstetrics &amp; Gynaecology Specialist, 2003 - 2005</p>
                  <h3>CERTIFICATIONS AND TRAINING:</h3>
                  <p>Certified IVF Specialist - Republic of Turkey Ministry of Health’s Assisted Reproductive Technology
                    and IVF Clinician Education Programme, 2004-2005</p>
                  <h3>PUBLICATIONS)</h3>

                  <p>1.Baltaci V, Satiroglu H, Unsal E, Uner O, Ergun MA, Batioglu S, Sonmezer M, Kabukcu C, Aydinuraz B, Aktas Y. Preimplantation genetic diagnosis in two couples with balanced reciprocal translocations. Eur J Obstet Gynecol Reprod Biol. 2006 Jul 28 PMID: 16876931</p>
                  <p>2.Baltaci V, Satiroglu H, Kabukcu C, Unsal E, Aydinuraz B, Uner O, Aktas Y, Cetinkaya E, Turhan F, Aktan A. Relationship between embryo quality and aneuploidies. Reprod Biomed Online. 2006 Jan;12(1):77-82. PMID: 16454940</p>
                  <p>3.Tufan AC, Satiroglu-Tufan NL, Aydinuraz B, Satiroglu MH, Aydos K, Bagci H. No association of the CAG repeat length in exon 1 of the androgen receptor gene with idiopathic infertility in Turkish men: implications and literature review. Tohoku J Exp Med. 2005 Jun;206(2):105-15. Review. PMID: 15888966</p>
                  <p>4.Atabekoglu CS, Sonmezer M, Aydinuraz B, Dunder I. Extremely elevated CA 125 level due to an unruptured large endometrioma. Eur J Obstet Gynecol Reprod Biol. 2003 Sep 10;110(1):105-6. PMID: 12932883</p>
                  <p>5.Cok I, Donmez MK, Hakan Satiroglu M, Aydinuraz B, Henkelmann B, Kotalik J, Schramm KW, Concentration of polychlorinated dibenzo-p-dioxins, polychlorinated dibenzofurans and dioxin-like PCBs in human adipose tissue from Turkish men. Chemosphere. 2007 Jan;66(10):1955-1961. Epub 2006 Sep 8</p>
                  <p>6. Concentrations of polychlorinated dibenzo-p-dioxins (PCDDs), polychlorinated dibenzofurans (PCDFs), and dioxin-like PCBs in adipose tissue of infertile men. Cok I, Donmez MK, Satiroğlu MH, Aydinuraz B, Henkelmann B, Shen H, Kotalik J, Schramm KW. Arch Environ Contam Toxicol. 2008 Jul;55(1):143-52. Epub 2008 Jan 1</p>
                  <p>7. Uterine rupture at the 29th week of subsequent pregnancy after hysteroscopic resection of uterine septum. Satıroğlu MH, Gözüküçük M, Cetinkaya SE, Aydınuraz B, Kahraman K. Fertil Steril. 2008 Dec 3</p>
                  <p>8. Live twin birth after successful treatment of a ruptured heterotopic pregnancy by laparoscopy Murat Gözüküçük, Şerife Esra Çetinkaya, Batu Aydınuraz, Korhan Kahraman, Mustafa Hakan Şatıroğlu Gynecological Surgery 04/2012; 8(2):239-241</p>
                  <p>9. Clinical pregnancy after uterus transplantation Erman Akar M,Ozkan O,Aydinuraz B,Dirican K,Cincik M,Mendilcioglu I,Simsek M,Gunseren F,Kocak H,Ciftcioglu,Gecici O,Ozkan O. Fertil Steril. 2013 Nov;100(5):1358-1363</p>
                  <p>10.<a href="https://www.ncbi.nlm.nih.gov/pubmed/27638900">Uterus transplantation: From animal models through the first heart beating pregnancy to the first human live birth.</a>Ozkan O, Dogan NU, Ozkan O, Mendilcioglu I, Dogan S, Aydinuraz B, Simsek M.<span class="jrnl" title="Women's health (London, England)">Womens Health (Lond)</span>. 2016 Jul;12(4):442-9. doi: 10.1177/1745505716653849. Review.PMID:27638900</p>
                  <p>11.<a href="https://www.ncbi.nlm.nih.gov/pubmed/27734719">Artificial oocyte activation after intracytoplasmic morphologically selected sperm injection: A prospective randomized sibling oocyte study.</a>&nbsp;Aydinuraz B, Dirican EK, Olgan S, Aksunger O, Erturk OK.<span class="jrnl" title="Human fertility (Cambridge, England)">Hum Fertil (Camb)</span>. 2016 Dec;19(4):282-288.PMID:27734719</p>
                  <p>12.Conventional IVF revisited: Is ICSI better for non-male factor infertility? Randomized controlled
                    double blind study <a href="https://pubmed.ncbi.nlm.nih.gov/?term=Isikoglu+M&amp;cauthor_id=33221561">M Isikoglu</a>, <a href="https://pubmed.ncbi.nlm.nih.gov/?term=Avci+A&amp;cauthor_id=33221561">A Avci</a>, <a href="https://pubmed.ncbi.nlm.nih.gov/?term=Kendirci+Ceviren+A&amp;cauthor_id=33221561">A Kendirci Ceviren</a>, <a href="https://pubmed.ncbi.nlm.nih.gov/?term=Ayd%C4%B1nuraz+B&amp;cauthor_id=33221561">B Aydınuraz</a>, <a href="https://pubmed.ncbi.nlm.nih.gov/?term=Ata+B&amp;cauthor_id=33221561">B Ata</a> J Gynecol Obstet Hum Reprod. 2021 Sep;50(7):101990. <a href="https://linkinghub.elsevier.com/retrieve/pii/S2468784720303603">DOI: 10.1016/j.jogoh.2020.101990.</a></p>
                  <p>13.Comparison of ICSI and conventional IVF in non-male factor patients with less than four oocytes <a href="https://pubmed.ncbi.nlm.nih.gov/?term=Isikoglu+M&amp;cauthor_id=35224650">Mete Isikoglu</a>, <a href="https://pubmed.ncbi.nlm.nih.gov/35224650/">Ayse Kendirci Ceviren</a>, <a href="https://pubmed.ncbi.nlm.nih.gov/?term=Cetin+T&amp;cauthor_id=35224650">Tugba Cetin</a>, <a href="https://pubmed.ncbi.nlm.nih.gov/?term=Avci+A&amp;cauthor_id=35224650">Aysenur Avci</a>, <a href="https://pubmed.ncbi.nlm.nih.gov/?term=Aydinuraz+B&amp;cauthor_id=35224650">Batu Aydınuraz</a>, <a href="https://pubmed.ncbi.nlm.nih.gov/?term=Akgul+OK&amp;cauthor_id=35224650">Ozlem Karabay AkguL</a>, <a href="https://pubmed.ncbi.nlm.nih.gov/?term=Karaca+M&amp;cauthor_id=35224650">Mehmet Karaca</a> Arch Gynecol Obstet 2022 Aug;306(2):493-499.
                    DOI: 10.1007/s00404-022-06471-x.
                  </p>
                  <h3>SPECIAL INTERESTS:</h3>
                  <p>Extensive readings on etymology, history and philosophy.</p>
                  <p>Model making.</p>
                  <p>Amateur astronomic observation.</p>
                  {/* <h3>Yurtiçi Yayınlar</h3>

                  <p>1. ILTEMİR, Candan; CENGİZ, Bora; AYDINURAZ, Batu; SÖYLEMEZ, Feride Eosinophilic granuloma and pregnancy Gynecology Obstetrics and Reproduction Medicine, 2004,10(1):66-67</p>
                  <p>2.SÖNMEZER, Murat; ATABEKOĞLU, Cem; BERKER, Bülent; KABUKÇU, Cihan; AYDINURAZ, Batu; ŞATIROĞLU, Hakan Hysteroscopic treatment of uterine septum: Reproductive outcome Gynecology Obstetrics and Reproduction Medicine, 2001,7(2):106-108</p>
                  <p>3.M. Ziya GÜNENÇ, Banu BİNGÖL, Selçuk TABAK, Batu AYDİNURAZ Peritoneal Tuberculosis with Elevated Ca 125 Levels, a Diagnostic Challenge and Value of Laparoscopy and ADA Level in the Differential Diagnosis Gynecol Obstet Reprod Med,GORM, 2005; 11:215-217</p>
                  <p>4. Cinsel Yolla Bulaşan Hastalıklar Ve Pelvik Inflamatuvar Hastalıkta Güncel Bilgi Ve Yaklaşımlar Dr. Batu AYDINURAZa,Dr. Cihan KABUKÇUa,Dr. M. Hakan ŞATIROĞLUb Turkiye Klinikleri J Gynecol Obst-Special Topics 2008;1(5):57-66</p>
                  <p>5. Infertil Çiftte Kadının Değerlendirilmesi Ve Tedavinin Düzenlenmesi Dr. Batu AYDINURAZ,Dr. Cihan KABUKÇUa,Dr. M. Hakan ŞATIROĞLU Turkiye Klinikleri J Urology-Special Topics 2008, 1(1):8-16</p> */}

                  {/* <h3>Poster Sunumları:</h3>


                  <p>AA-10th Biennial Conference of Alpha, Scientists in Reproductive Medicine, 9–11 May 2014, Antalya, Turkey Fertilization and embryo quality with and without artificial oocyte activation in teratozoospermia after intracytoplasmic morphologically selected sperm injection: a prospective randomized sibling-oocyte study Enver Kerem Dirican, Ozlem Aksunger, Leman Rasa Erel, Batu Aydinuraz &nbsp; &nbsp; &nbsp;&nbsp; Memorial Hospital of Antalya RBM Online May 2014Volume 28, Supplement 1, Pages&nbsp;S13–S14</p>
                  <p>AB- PGDIS 2013 Congress 12 th International PGD Conference, Istanbul, 8-11 May 2013 Healthy ongoing pregnancy achieved by Preimplantation Genetic Diagnosis using array comparative genomic hybridization in a couple with reciprocal translocation carrier female and male with Klinefelter Syndrome: Case report E.K. Dirican, C. Beyazyurek, C.C. Yapan, O. Aksunger, Z. Salar, B. Aydinuraz, C.G. Ekmekci. Memorial Hospital of Antalya, Antalya, Turkey, 2Memorial Hospital, Istanbul, Turkey RBM Online 26 Suppl. 1 (2013) S1–S11</p>
                  <p>AC-Sixth International Symposium on Preimplantation Genetics (19-21 May 2005), London, UK</p>
                  <p>1.Relationship between embryo quality and aneuploidies Satiroglu H, Baltaci V, Kabukçu C, Ünsal E, Aydinuraz B, Üner Ö, Aktas Y, Çetinkaya E, Turhan F Gen ART Woman Health, IVF and Biotechnology Centre,Ankara Turkey RBM Online,Volume 10,suppl.2,2005</p>
                  <p>2.A simplified blastomere fixation technique Baltaci V*, Satiroglu H, Aydinuraz B, Unsal E, Uner O, Cetinkaya E, Kabukcu C, Aktas Y, Turhan F Gen ART Woman Health, IVF and Biotechnology Centre, Ankara, Turkey RBM Online,Volume 10,suppl.2,2005</p>
                  <p>AD-ASRM 2005 Philedelphia Trinucleotide (CAG) Repeat Length in Exon 1 of the Androgen Receptor Gene of Turkish Males with Idiopathic Infertility. A. C. Tufan, N. L. Satiroglu-Tufan, B. Aydinuraz, M. H. Satiroglu. Pamukkale University School of Medicine, Denizli, Turkey; Gen-Art Women Health, IVF and Reproductive Biotechnology, Ankara, Turkey; Ankara University School of Medicine, Department of Obstetrics and Gynecology, Ankara, Turkey. <span class="journalTitleSp">&nbsp;Fertility and Sterility</span>, <span class="issueVolSp">Vol. 82</span>, <span class="pageRange">S179 September 2004 </span></p>
                  <p>AE-European Human Genetics Conference 2006, Amsterdam, The Netherlands Preimplantation genetic diagnosis in two couples with balanced reciprocal translocation Baltaci V, Satiroglu H, Unsal E, Uner O, Ergun MA, Batioglu S, Sonmezer M, Kabukcu C, Aydinuraz B, Aktas Y Gen-Art Women Health, IVF and Reproductive Biotechnology, Ankara, Turkey; Ankara University School of Medicine, Department of Obstetrics and Gynecology,Ankara, Turkey. European Journal Of Human Genetics,Volume 14 Supplement 1,p.203 May 2006</p>
                  <p>AF-8th Internatonal Symposium on Pharmaceutical Sciences Ankara,TURKEY</p>
                  <p>1.Organochlorine Pesticide (OCP) and Polychlorinated Biphenyl (PCB) Levels in Adipose Tissue of Infertile Men T.Çalık Durmaz,M.H.Şatıroglu,B.Aydınuraz,C.Kabukçu,İ.Çok Gazi University,Faculty of Pharmacy,Department of Toxicology,Ankara,Turkey Ankara University School of Medicine,Department of Obstetrics and Gynecology,Ankara, Turkey Gen-Art Women Health, IVF and Reproductive Biotechnology, Ankara, Turkey <span><span class="style7">FABAD J. Pharm. Sci., 31(1), 7-14, 2006</span></span></p>
                  <p>2.Levels of Dioxin-like PCB Congeners in Adipose Tissue of Turkish Men M.Keski Dönmez, İ.Çok, M.H.Şatıroğlu, B.Aydınuraz, B.Henkelmann, J.Kotalik K.W.Schramm Gazi University,Faculty of Pharmacy, Department of Toxicology,Ankara,Turkey Ankara University School of Medicine,Department of Obstetrics and GynecologyAnkara, Turkey Gen-Art Women Health, IVF and Reproductive Biotechnology, Ankara, Turkey GSF-National Research Center for Environment and Health,Institute of Ecological Chemistry,Neuherberg,Germany <span ><span class="style7">FABAD J. Pharm. Sci., 31(1), 7-14, 2006</span></span></p>
                  <p>AG-V. Kongress der Deutsch-Türkischen Gynäkologengesellschaft II. Internationaler Kongress für Reproduktionsmedizin, May 2003, Antalya,Turkey</p>
                  <p>1.OP-014 PREMALİGN SERVİKS LEZYONLARINDA TELOMERAZ AKTİVİTESİNİN TANISAL DEĞERİ E.Şimşek, B. Aydınuraz, B. Özmen, A. Tükün, N. Kutlay, İ. Dünder, F. Ortaç</p>
                  <p>2. VP-003 VAGINAL CUFF PROLAPSUSUNDA WILLIAMS OPERASYONU DENEYİMİMİZ E.Şimşek, K. Kahraman, B. Aydınuraz, C. İltemir, C. Atabekoğlu, F. Ortaç</p>
                  <p>3.OP-038 NON-OBSTRÜKTİF AZOOSPERMİK İNFERTİLİTEDE TELOMERAZ HTERT KATALİTİK SUBÜNİTESİ, VİMENTİN VE SİTOKERATİN EKSPRESYONUNUN ICSI İÇİN GERM HÜCRE ELDE ETME YÖNÜNDE PREDİKTİF DEĞERİ B. AydInuraz, E.Şimşek, B. Özmen, L. Demirel1, K. Aydos, C. Ünlü,Ö. Tulunay</p>
                  <p>4.P-139 OVER KANSERİNDE ERKEN REKÜRRENSİN BELİRLENMESİNDE TELOMERAZ AKTİVİTESİNİN YERİ B. Özmen, F. Ortaç, A. Tükün, M. Güngör, B. Aydınuraz, E. Şimşek, T. Eroğlu Elmas, C. Atabekoğlu</p>
                  <p>5. P-146 NON-OBSTRÜKTİF AZOOSPERMİK İNFERTİLİTEDE SERUM FSH, LH, TOTAL TESTOSTERON DÜZEYİ VE TESTİS VOLÜMÜ İLE TESTİS DOKUSUNUN HİSTOPATOLOJİK MATÜRASYON BELİRTECİ OLAN JOHNSEN SKORUNUN ICSI İÇİN GERM HÜCRE ELDE ETME YÖNÜNDE PREDİKTİF DEĞERİ B. Aydınuraz, B. Özmen, E.Şimşek, L. Demirel, C.Ünlü, K. Aydos, .Ö.Tulunay</p>
                  <p>6.P-148 PREMALİGN SERVİKS LEZYONLARINDA SMEAR, YÖNLENDİRİLMİŞ BİYOPSİ VE NİHAİ PATOLOJİLERİN KARŞILAŞTIRILMASI E.Şimşek , B. Özmen, B. Aydınuraz, C. İltemir, K. Kahraman, T. Eroğlu Elmas, İ. D.nder, F. Ortaç</p>
                  <p>7. OP-016 OVER KANSER‹ SECOND-LOOK OPERASYONUNDA POZİTİF SECOND-LOOK VAKALARIN BELİRLENMESİNDE TELOMERAZ AKTİVİTESİNİN YERİ B. Özmen, F. Ortaç, A. Tükün, M. Güngör, G. Gümüş, E. Şimşek, B. Aydınuraz, T. Eroğlu Elmas, K. Kahraman</p> */}

                </div>
              </>
            }
          }}
        </LocaleContext.Consumer>

      </S.Section>
    </S.Wrapper >
  );
}
