
import { Link } from 'react-router-dom';
import S from './style'
import TranslateStatic from '../../locales/TranslateStatic';
import YumurtlamaImg from '../../assets/tesa.jpeg'
export function Tesa() {
  return (
    <S.Wrapper>
      <S.Top>
        {/* <S.Header>
          <TranslateStatic string="aboutUsH2" />
        </S.Header> */}
        <S.Span>
          <Link to="./"><TranslateStatic string="home" /></Link> > <Link><TranslateStatic string="TESA / MIKRO TESE" /></Link>
        </S.Span>
      </S.Top>
      {/* <S.Sertifika>
        <Certification />
      </S.Sertifika> */}
      <S.Section>
        <div className='banner'>
          <div className='baslik'>
            <h1>
              <TranslateStatic string="TESA / MIKRO TESE" />
            </h1>
            <h3>
            <TranslateStatic string="TESA / MIKRO TESE2" />
              </h3>
          </div>
          <div className='imgwrap'>

          <img className='foregroundImg' src={YumurtlamaImg} />
          <img className="backgroundImg" src={YumurtlamaImg} />
          </div>



        </div>

        <p>
          <TranslateStatic string="tesaP1" />
        </p>
        <p>
          <TranslateStatic string="tesaP2" />
        </p>
        <h3><TranslateStatic string="tesah3-1" /></h3>
        <p>
          <TranslateStatic string="tesaP3" />
        </p>
        <h3><TranslateStatic string="tesah3-2" /></h3>
        <p>
          <TranslateStatic string="tesaP4" />
        </p>
        <p>
          <TranslateStatic string="tesaP5" />
        </p>
        <p>
          <TranslateStatic string="tesaP6" />
        </p>
        <p>
          <TranslateStatic string="tesaP7" />
        </p>
       
        <h3><TranslateStatic string="tesaH4" /></h3>
        <p>
          <TranslateStatic string="tesaP9" />
        </p>
        {/* <h3><TranslateStatic string="tesaH5" /></h3>
        <p>
          <TranslateStatic string="tesaP10" />
        </p> */}
      </S.Section>
    </S.Wrapper >
  );
}
