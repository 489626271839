
import { Link } from 'react-router-dom';
import S from './style'
import TranslateStatic from '../../locales/TranslateStatic';
import YumurtlamaImg from '../../assets/tupBebek.png'
export function TupBebek() {
  return (
    <S.Wrapper>
      <S.Top>
        {/* <S.Header>
          <TranslateStatic string="aboutUsH2" />
        </S.Header> */}
        <S.Span>
          <Link to="./"><TranslateStatic string="home" /></Link> > <Link><TranslateStatic string="tüp bebek tedavisi" /></Link>
        </S.Span>
      </S.Top>
      {/* <S.Sertifika>
        <Certification />
      </S.Sertifika> */}
      <S.Section>
        <div className='banner'>
          <div className='baslik'>
            <h1>
              <TranslateStatic string="tüp bebek tedavisi" />
            </h1>
            {/* <h3>
            (OVÜLASYON İNDÜKSİYONU)
            </h3> */}
          </div>
          <div className='imgwrap'>

          <img className='foregroundImg' src={YumurtlamaImg} />
          <img className="backgroundImg" src={YumurtlamaImg} />
          </div>



        </div>

        <h3><TranslateStatic string="tupbebekH3" /></h3>
        <p>
          <TranslateStatic string="tupbebekP1" />
        </p>
        {/* <p>
          <TranslateStatic string="tupbebekP2" />
        </p> */}
        <h3><TranslateStatic string="tupbebekH4" /></h3>
        <p>
          <TranslateStatic string="tupbebekP3" />
        </p>
        <p>
          <TranslateStatic string="tupbebekP4" />
        </p>
        <h3><TranslateStatic string="tupbebekH5" /></h3>
        <p>
          <TranslateStatic string="tupbebekP6" />
        </p>
        <h3><TranslateStatic string="tupbebekH6" /></h3>
        <p>
          <TranslateStatic string="tupbebekP7" />
        </p>
        <h3><TranslateStatic string="tupbebekH7" /></h3>
        <h4><TranslateStatic string="tupbebekH8" /></h4>
        <p>
          <TranslateStatic string="tupbebekP8" />
        </p>
        <h4><TranslateStatic string="tupbebekH9" /></h4>
        <p>
          <TranslateStatic string="tupbebekP9" />
        </p>
        <h4><TranslateStatic string="tupbebekH10" /></h4>
        <p>
          <TranslateStatic string="tupbebekP10" />
        </p>
        <h4><TranslateStatic string="tupbebekH11" /></h4>
        <p>
          <TranslateStatic string="tupbebekP11" />
        </p>
        <h4><TranslateStatic string="tupbebekH12" /></h4>
        <p>
          <TranslateStatic string="tupbebekP12" />
        </p>
      </S.Section>
    </S.Wrapper >
  );
}
