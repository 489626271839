
import { Link } from 'react-router-dom';
import S from './style'

import TranslateStatic from '../../locales/TranslateStatic';
import { LocaleContext } from '../../locale-context';
export function Teklif() {
  return (
    <S.Wrapper>
      <S.Top>
        <S.Header>
          <TranslateStatic string="teklifHeader" />
        </S.Header>
        <S.Span>
          <Link to="./"><TranslateStatic string="home" /></Link> > <TranslateStatic string="teklifHeader" />
        </S.Span>
      </S.Top>
      {/* <S.Sertifika>
        <Certification />
      </S.Sertifika> */}
      <S.Section>
        <h2><TranslateStatic string="teklifFormHeader" /></h2>
        <LocaleContext.Consumer>
          {(value) => {
            if (value === "en") {
              return <form action="">
                <input placeholder="Name" type="text" name="isim" />
                <input placeholder="Surname" type="text" name="soyisim" />
                <input placeholder="E-Mail" type="email" name="email" />
                <input placeholder="Phone" type="phone" name="phone" />
                <textarea placeholder="Description" name="description" />
                <input type="submit" value="Submit" />
              </form>
            } else if (value === "tr") {
              return <form action="">
                <input placeholder="Firma Adı" type="text" name="isim" />
                <input placeholder="İlgili Kişi" type="text" name="soyisim" />
                <input placeholder="E-Posta" type="email" name="email" />
                <input placeholder="Telefon" type="phone" name="phone" />
                <input placeholder="Talep Konusu" type="text" name="email" />
                <textarea placeholder="Açıklama" name="description" />
                <input type="submit" value="Gönder" />
              </form>
            }
          }}
        </LocaleContext.Consumer>
      
      </S.Section>
    </S.Wrapper>
  );
}
