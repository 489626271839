import styled from "styled-components";

const S = {
    Wrapper: styled.header`
    position: ${props => props.fontColor ? "absolute" : "relative"};
    box-shadow:${props => !props.fontColor ? "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" : "none"}; 
    top: 0;
    left: 0;
    right: 0;
    display:grid;
    box-sizing:border-box;
    grid-template-columns: 200px 2fr 100px;
    grid-template-rows: auto;
    grid-template-areas:"headertop headertop headertop"
    "logo headerbottomcenter headerbottomright";
    flex-direction:row;
    justify-content: space-between;
    padding: 5px 40px;
    z-index: 50;
    border-radius:10px;
    @media (max-width:648px){
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    `,
    Logo: styled.a`
    width:200px;
    z-index:200;
    padding:20px 10px;
    grid-area: logo;
    cursor:pointer;
    transition:300ms;
    &:hover{
        transition:300ms;
        padding:20px 15px;
        img{
            width:100%;
        }
    }
    @media (max-width:648px){
        width:100px;
     }
    img{
        width:100%;
    }
    
    `,
    MobileMenu: styled.div`
    display: none;
    position:fixed;
    z-index:10000 !important;
    bottom:50px;
    right:40px;
    @media (max-width:648px){
        display: block;
        z-index:10000 !important;
        position:fixed;
    }
    .ac{
        border-radius:20px;
        background:black;
        color:white;
        padding:10px 20px;
        display:flex;
        justify-content:center;
        align-items:center;
        svg{
            width:20px;
        }
    }
  
    `,
    Nav: styled.ul`
    margin:0;
    margin-left:20px;
    padding: 0;
    vertical-align: middle;
    grid-area: headerbottomcenter;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-content: center;
    align-items: center;
    box-sizing:border-box;
    .responseLi{
        display:flex;
        flex-direction:column;
        color:#01aecb !important;
            list-style: none;
            padding: 0 10px;
            color:${props => props.fontColor ? "white" : "black"};
            font-size:1em;
            a{
                font-size:1em;
                padding-left:20px;
                margin:5px;
               
            }
    }
    li{
        list-style: none;
        padding: 0 10px;

        color:${props => props.fontColor ? "white" : "black"};
        font-size:14px;
        
        a{
            text-decoration: none;
            font-weight: 800;
            color:${props => props.fontColor ? "white" : "black"};
            &:visited{
                // color:${props => props.fontColor ? "white" : "black"};
            }
            &:hover{
                color:#00A9C4;
            }
            @media (max-width:648px){
                color:black;
                &:visited{
                    color:black;
                }
            }  
        }
        // &:last-child{
        //     background:white;
        //     padding:10px;
        //     border-radius:40px;
        //     transition:200ms;
        //     a{
        //         color:black;
        //     }
        //     &:hover{
        //         background :#00A9C4;
        //         transition:200ms;
        //         a{
        //             color:white;
        //         }
        //     }
        // }
        @media (max-width:648px){
            padding:10px;
        }
    }
    #dropdown {
            position: relative;
            display: inline-block;
            text-decoration: none;
            font-weight: 800;
            color:${props => props.fontColor ? "white" : "black"};
            @media (max-width:648px){
                color:black;
                &:visited{
                    color:black;
                }
            }  
            &:hover{
                .dropdown-content {
                    z-index:1000;
                    /* display: block; */
                    display: flex;
                    flex-direction: column;
                    a{
                        margin: 10px 0px;
                    }
                }
            }
        }
        .dropdown-content {
                display:${props => props.click ? "flex" : "none"};
                // display: none;
                position: absolute;
                min-width: 260px;
                padding-top:20px;
                color:black;
                @media (max-width:648px){
                    color:black;
                    &:visited{
                        color:black;
                    }
                }  
            .dropdown-in{
                color:black;
                display:flex;
                flex-direction:column;
                padding: 12px 16px;
                // background-color: #f9f9f933;
                background:white;
                box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
                z-index: 1;
                border-radius:10px;
                a{
                    color:black;
                    &:hover{
                        color:#01aecb;
                    }
                }
            }
        }
    @media (max-width:648px){
        display: none;
        width: 100%;
        // height: 350px;
        position: fixed;
        top: 91px;
        // left: -100%;
        opacity: 0;
        transition: all 0.5s ease;
        flex-direction: column;
        list-style-type: none;
        grid-gap: 0px;
        margin:0px;
        box-sizing:border-box;
        z-index: 20;
        justify-content: flex-end;
        padding-bottom: 100px;
        li{
                padding:10px 40px !important;
                width:100%;
                box-sizing:border-box;
                font-size:1em;
            a{
                font-size:1em;
                text-align:left;
                
            }
        }
       
        ${props => {
            if (props.click) {
                return `
                position:fixed;
                top:auto;
                bottom:0px;
                display: flex;
                background: white;
                left: 0;
                opacity: 1;
                transition: all 0.5s ease;
                z-index: 20;
                align-content: center;
                // padding: 10px 0px 0px 20px;
                padding-top:20px;
                height:auto;
                border-radius:20px 20px 0px 0px;
                `
            }
        }}
        /* .active {
            background: #9899d1;
            left: 0;
            opacity: 1;
            transition: all 0.5s ease;
            z-index: 1;
            align-content: center;
            padding-left: 0px;
        } */
        }
    `,
    Search: styled.div`
     grid-area: headerbottomright;
     display: flex;
    justify-content:flex-end;
    flex-direction: row;
    align-content: center;
    align-items: center;
    position: relative;
    li{
        list-style:none;
        a{
            text-decoration:none;
            border-radius: 40px;
            border:none;
            padding:10px;
            background-color: #d41031;
            color:white;
            font-size:14px;
        }
    }
    input{
        // border: 1px solid #D41031;
        border-radius: 5px;
        border:none;
        padding:10px;
        background-color: #d41031;
        width:120px;
        color:white;
        &::placeholder {
            font-size:24px;
            color: white;
            opacity: 1; /* Firefox */
          }
    }
    .lang{
        margin: 0 5px;
        ul{

            display: flex;
            flex-direction: row;
            list-style: none;
            li{
                margin: 0 5px;
            }
        }
    }
    @media (max-width:648px){
        // display: none;
    }
    `,
    SearchDropdown: styled.ul`
        display: ${props => props.display ? 'flex' : 'none'};
        position: absolute;
        padding: 0;
        width:250px;
        height: 350px;
        z-index: 1000;
        background-color: white;
        overflow-y: auto;
        top:60px;
        flex-direction: column;
        list-style: none;
        li{
            margin:2px 0;
            padding: 10px;
            a{
                text-decoration: none;
                color:black;
            }
        }
    `,
    Opener: styled.div`
    display:${props => props.display};
    position:absolute;
    border-radius:4px;
    /* margin-top:150px; */
    top:70px;
    background-color:white;
    padding:10px;
    max-height:400px;
    overflow-y:auto;
    &::-webkit-scrollbar {
            width: .5em;
            }
    
            &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            }
            &::-webkit-scrollbar-thumb:hover{
            transition: 300ms;
            background-color: black;
            }
    
            &::-webkit-scrollbar-thumb {
            transition: 300ms;
            background-color: #009900;
            outline: 1px solid #009900;
            }
    
    `
}

export default S