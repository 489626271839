
import { Link } from 'react-router-dom';
import S from './style'

import TranslateStatic from '../../locales/TranslateStatic';
import YumurtlamaImg from '../../assets/endoskopik.jpeg'
export function Endoskopik() {
  return (
    <S.Wrapper>
      <S.Top>
        {/* <S.Header>
          <TranslateStatic string="aboutUsH2" />
        </S.Header> */}
        <S.Span>
          <Link to="./"><TranslateStatic string="home" /></Link> > <Link><TranslateStatic string="endoskopik ameliyatlar" /></Link>
        </S.Span>
      </S.Top>
      {/* <S.Sertifika>
        <Certification />
      </S.Sertifika> */}
      <S.Section>
        <div className='banner'>
          <div className='baslik'>
            <h1>
              <TranslateStatic string="endoskopik ameliyatlar" />
            </h1>
          </div>
          <div className='imgwrap'>

            <img className='foregroundImg' src={YumurtlamaImg} />
            <img className="backgroundImg" src={YumurtlamaImg} />
          </div>



        </div>

        <p>
          <TranslateStatic string="endoskopikP1" />
        </p>
        <h3><TranslateStatic string="endoskopikH1" /></h3>
        <p>
          <TranslateStatic string="endoskopikP2" />
        </p>
        <ul>
          <li>
            <TranslateStatic string="endoskopikLi1" />
          </li>
          <li>
            <TranslateStatic string="endoskopikLi2" />
          </li>
          <li>
            <TranslateStatic string="endoskopikLi3" />
          </li>
          <li>
            <TranslateStatic string="endoskopikLi4" />
          </li>
          <li>
            <TranslateStatic string="endoskopikLi5" />
          </li>
          <li>
            <TranslateStatic string="endoskopikLi6" />
          </li>
        </ul>
        <p>
          <TranslateStatic string="endoskopikP6" />
        </p>
        <h3><TranslateStatic string="endoskopikH2" /></h3>
        <p>
          <TranslateStatic string="endoskopikP3" />
        </p>
        {/* <h3><TranslateStatic string="endoskopikH3" /></h3>
        <p>
          <TranslateStatic string="endoskopikP4" />
        </p> */}
        <ul>
          <li>
            <TranslateStatic string="endoskopikLi7" />
          </li>
          <li>
            <TranslateStatic string="endoskopikLi8" />
          </li>
          <li>
            <TranslateStatic string="endoskopikLi9" />
          </li>
          <li>
            <TranslateStatic string="endoskopikLi10" />
          </li>
        </ul>
        <p>
          <TranslateStatic string="endoskopikNot" />
        </p>
      </S.Section>
    </S.Wrapper >
  );
}
