
import { Link } from 'react-router-dom';


import { LocaleContext } from '../../../locale-context';

export function Table() {
    return (
        <LocaleContext.Consumer>
            {(value) => {
                if (value === "en") {
                    return <div>

                        <div class="divTable">
                            <div class="divTableBody">
                                <div class="divTableRow">
                                    <div class="divTableCell">PROCEDURE</div>
                                    <div class="divTableCell">RECOMMENDATION</div>
                                    <div class="divTableCell">EXPLANATION</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Diagnostic hysteroscopy</div>
                                    <div class="divTableCell">NO</div>
                                    <div class="divTableCell">In patients experiencing RIF, hysteroscopy may be beneficial</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Endometrial receptivity tests (ERA)</div>
                                    <div class="divTableCell">NO</div>
                                    <div class="divTableCell">Lack of clear benefit</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Reproductive immunology tests and treatments</div>
                                    <div class="divTableCell">NO</div>
                                    <div class="divTableCell">No reliable normal reference ranges/ absence of any rationale, documented side-effects, and no clinical benefit</div>
                                </div>
                            </div>
                        </div>

                        <h2>Laboratory tests and interventions</h2>

                        <div class="divTable">
                            <div class="divTableBody">
                                <div class="divTableRow">
                                    <div class="divTableCell">PROCEDURE</div>
                                    <div class="divTableCell">RECOMMENDATION</div>
                                    <div class="divTableCell">EXPLANATION</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Artificial oocyte activation</div>
                                    <div class="divTableCell">NO</div>
                                    <div class="divTableCell">Can be effective for cases of TFF (0% 2PN), very low fertilisation ({'<'}30%) or globozoospermia</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Mithocondrial replacement therapy</div>
                                    <div class="divTableCell">NO</div>
                                    <div class="divTableCell">Not allowed outside strict research protocols</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">IVA</div>
                                    <div class="divTableCell">NO</div>
                                    <div class="divTableCell">Limited efficacy, potentially high cost and safety concerns</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">IVM</div>
                                    <div class="divTableCell">NO</div>
                                    <div class="divTableCell">Absence of long-term safety data, procedural reliability, and effectiveness.</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Sperm DNA damage testing/tx</div>
                                    <div class="divTableCell">NO</div>
                                    <div class="divTableCell">Only under strict research protocols</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Artificial sperm activation</div>
                                    <div class="divTableCell">NO</div>
                                    <div class="divTableCell">Only for primary or secondary total asthenozoospermia</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell"><b>Sperm evaluation and selection</b></div>
                                    <div class="divTableCell"></div>
                                    <div class="divTableCell"></div>

                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">PICSI</div>
                                    <div class="divTableCell">NO</div>
                                    <div class="divTableCell">Limited standardisation and the limited clinical value</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">MACS</div>
                                    <div class="divTableCell">NO</div>
                                    <div class="divTableCell">Insufficient evidence</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Microfluidics</div>
                                    <div class="divTableCell">NO</div>
                                    <div class="divTableCell">More research is needed</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">IMSI</div>
                                    <div class="divTableCell">NO</div>
                                    <div class="divTableCell">No benefit shown</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Growth factor-supplemented embryo culture medium</div>
                                    <div class="divTableCell">NO</div>
                                    <div class="divTableCell">Insufficient evidence</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Assisted hatching</div>
                                    <div class="divTableCell">NO</div>
                                    <div class="divTableCell">No benefit, increase in MZ twinning</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell"><b>Genetic testing/treatments</b></div>
                                    <div class="divTableCell"></div>
                                    <div class="divTableCell"></div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">PGT-A</div>
                                    <div class="divTableCell">NO</div>
                                    <div class="divTableCell">May decrease time to pregnancy in specific patient groups</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">NiPGT</div>
                                    <div class="divTableCell">NO</div>
                                    <div class="divTableCell">Not suitable for clinical application yet</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Mitochondria DNA load measurement</div>
                                    <div class="divTableCell">NO</div>
                                    <div class="divTableCell">Both niPGT and mtDNA load measurements are to be considered in research phase</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Time-lapse imaging (TLI)</div>
                                    <div class="divTableCell">NO</div>
                                    <div class="divTableCell">Not been shown to improve the LBR</div>
                                </div>
                            </div>
                        </div>

                        <h2>Clinical Management</h2>

                        <div class="divTable">
                            <div class="divTableBody">
                                <div class="divTableRow">
                                    <div class="divTableCell">PROCEDURE</div>
                                    <div class="divTableCell">RECOMMENDATION</div>
                                    <div class="divTableCell">EXPLANATION</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">PRP (Platelet Rich Plasma)</div>
                                    <div class="divTableCell">NO</div>
                                    <div class="divTableCell">Not recommended outside strict research protocols</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Duostim</div>
                                    <div class="divTableCell">NO</div>
                                    <div class="divTableCell">No data on the efficacy and safety. Can be used for urgent fertility preservation but requires further research in poor responders</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Adjuncts during ovarian stimulation</div>
                                    <div class="divTableCell">NO</div>
                                    <div class="divTableCell">Metformin, GH, testosterone, DHEA, aspirin, indomethacin,  sildenafil</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Intravaginal ve intrauterine culture device</div>
                                    <div class="divTableCell">NO</div>
                                    <div class="divTableCell">No evidence</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Additions to culture media (HA)</div>
                                    <div class="divTableCell">YES?</div>
                                    <div class="divTableCell">HA addition to transfer media is recommended to be performed only within a single embryo transfer policy program</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Endometrial scratching</div>
                                    <div class="divTableCell">NO</div>
                                    <div class="divTableCell">Further studies are needed if it is to be recommended</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Flushing of the uterus (Hcg, G-CSF, emryo culture supernatant, seminal plasma)</div>
                                    <div class="divTableCell">NO</div>
                                    <div class="divTableCell">Further trials are necessary</div>

                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Stem cell mobilization</div>
                                    <div class="divTableCell">NO</div>
                                    <div class="divTableCell">No rationale. Further preclinical studies should evaluate the relevance of this technique </div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Steroids</div>
                                    <div class="divTableCell">NO</div>
                                    <div class="divTableCell">Available data does not support routine administration</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Elective freeze-all</div>
                                    <div class="divTableCell">NO</div>
                                    <div class="divTableCell">Should only be adopted if there is a definite clinical indication</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">ICSI for non-male factor infertility</div>
                                    <div class="divTableCell">NO</div>
                                    <div class="divTableCell">ICSI should not be recommended in case of non-male factor infertility.</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Antioxidant therapy in male or female patients</div>
                                    <div class="divTableCell">NO</div>
                                    <div class="divTableCell">No sufficiently reliable and good quality evidence</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Complementary/alternative medicine</div>
                                    <div class="divTableCell">NO</div>
                                    <div class="divTableCell">Conflicting or no clinical studies</div>
                                </div>
                            </div>
                        </div>
                    </div>

                } else if (value === "tr") {
                    return <div>

                        <div class="divTable">
                            <div class="divTableBody">
                                <div class="divTableRow">
                                    <div class="divTableCell">İŞLEM</div>
                                    <div class="divTableCell">ÖNERİ</div>
                                    <div class="divTableCell">AÇIKLAMA</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Tanısal histeroskopi</div>
                                    <div class="divTableCell">HAYIR</div>
                                    <div class="divTableCell">Tekrarlayan tüp bebek başarısızlıklarında yararı olabilir</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Endometrial reseptivite testleri (ERA)</div>
                                    <div class="divTableCell">HAYIR</div>
                                    <div class="divTableCell">Açık bir yararı gösterilememiştir</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Reproductive immunology tests and treatments</div>
                                    <div class="divTableCell">HAYIR</div>
                                    <div class="divTableCell">Güvenilir referans aralıkları yok/gerekçe, yan etkiler ve klinik yararı belirsiz</div>
                                </div>
                            </div>
                        </div>

                        <h2>Laboratuvar testleri ve girişimleri</h2>

                        <div class="divTable">
                            <div class="divTableBody">
                                <div class="divTableRow">
                                    <div class="divTableCell">İŞLEM</div>
                                    <div class="divTableCell">ÖNERİ</div>
                                    <div class="divTableCell">AÇIKLAMA</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Yumurtanın yapay aktivasyonu</div>
                                    <div class="divTableCell">HAYIR</div>
                                    <div class="divTableCell">Döllenme yokluğu ve azlığı ve globozoospermide yararı olabilir</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Mitokondri replasman tedavisi</div>
                                    <div class="divTableCell">HAYIR</div>
                                    <div class="divTableCell">Araştırma protokolleri dışında kullanılmamalıdır</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">IVA</div>
                                    <div class="divTableCell">HAYIR</div>
                                    <div class="divTableCell">Sınırlı etkinlik, yüksek maliyet ve güvenlik sorunları</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">IVM</div>
                                    <div class="divTableCell">HAYIR</div>
                                    <div class="divTableCell">Uzun dönem riskleri, işlem güvenilirliği ve etkinliği belirsiz</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Sperm DNA hasarı test/tedavileri</div>
                                    <div class="divTableCell">HAYIR</div>
                                    <div class="divTableCell">Sadece araştırma protokollerinde uygulanmalıdır</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Spermin yapay aktivasyonu</div>
                                    <div class="divTableCell">HAYIR</div>
                                    <div class="divTableCell">Sadece sperm hücrelerinin tamamı hareketsiz ise yapılabilir</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell"><b>Sperm değerlendirme ve seçimi</b></div>
                                    <div class="divTableCell"></div>
                                    <div class="divTableCell"></div>

                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">PICSI</div>
                                    <div class="divTableCell">HAYIR</div>
                                    <div class="divTableCell">Standardizasyonu ve klinik yararı sınırlı</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">MACS</div>
                                    <div class="divTableCell">HAYIR</div>
                                    <div class="divTableCell">Yeterli kanıt yok</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Microfluidics (mikroçip)</div>
                                    <div class="divTableCell">HAYIR</div>
                                    <div class="divTableCell">Daha fazla araştırma gerekli</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Büyüme faktörü destekli embriyo kültür ortamı</div>
                                    <div class="divTableCell">HAYIR</div>
                                    <div class="divTableCell">Yeterli kanıt yok</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Assisted hatching (yuvalama)</div>
                                    <div class="divTableCell">HAYIR</div>
                                    <div class="divTableCell">Yararı yok, tek yumurta ikizi riskini arttırabilir</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell"><b>Genetik test/tedaviler</b></div>
                                    <div class="divTableCell"></div>
                                    <div class="divTableCell"></div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">PGT-A</div>
                                    <div class="divTableCell">HAYIR</div>
                                    <div class="divTableCell">Belirli hasta gruplarında gebeliğe ulaşma süresini kısaltabilir</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">niPGT</div>
                                    <div class="divTableCell">HAYIR</div>
                                    <div class="divTableCell">Klinik uygulama için henüz uygun değil</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Mitochondri DNA yükü ölçümü</div>
                                    <div class="divTableCell">HAYIR</div>
                                    <div class="divTableCell">niPGT ve mitokondrial DNA yükü ölçümü, araştırma aşamasındadır</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Time-lapse imaging (TLI)</div>
                                    <div class="divTableCell">HAYIR</div>
                                    <div class="divTableCell">Canlı doğum oranını arttırmamaktadır</div>
                                </div>
                            </div>
                        </div>

                        <h2>Klinik Yönetim</h2>

                        <div class="divTable">
                            <div class="divTableBody">
                                <div class="divTableRow">
                                    <div class="divTableCell">İŞLEM</div>
                                    <div class="divTableCell">ÖNERİ</div>
                                    <div class="divTableCell">AÇIKLAMA</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">PRP (Platelet Rich Plasma)</div>
                                    <div class="divTableCell">HAYIR</div>
                                    <div class="divTableCell">Araştırma protokolleri dışında önerilmemektedir</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Duostim</div>
                                    <div class="divTableCell">HAYIR</div>
                                    <div class="divTableCell">Etkinlik ve güvenlik verisi yok. Acil üreme koruma durumunda olabilir, düşük yumurtalık rezervinde kullanımı için ileri araştırmalar gerekir</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Yumurta geliştirme aşamasında ek tedaviler</div>
                                    <div class="divTableCell">HAYIR</div>
                                    <div class="divTableCell">Metformin, büyüme hormonu, testosterone, DHEA, aspirin, indomethacin, sildenafil</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Vagina ve rahim içine kültür gereci</div>
                                    <div class="divTableCell">HAYIR</div>
                                    <div class="divTableCell">Kanıt yoktur</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Kültür ortamına eklemeler (HA)</div>
                                    <div class="divTableCell">EVET?</div>
                                    <div class="divTableCell">Sadece tek embryo transfer politikası uygulanacaksa eklenmelidir</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Endometrial scratching (rahim içini çizme işlemi)</div>
                                    <div class="divTableCell">HAYIR</div>
                                    <div class="divTableCell">Daha fazla çalışmaya ihtiyaç vardır</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Rahim içinin yıkanması (Hcg, G-CSF, emryo culture supernatant, seminal plasma)</div>
                                    <div class="divTableCell">HAYIR</div>
                                    <div class="divTableCell">Daha fazla çalışmaya ihtiyaç vardır</div>

                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Kök hücre uygulamaları</div>
                                    <div class="divTableCell">HAYIR</div>
                                    <div class="divTableCell">Gerekçesi belirsiz. Klinik uygulama öncesi çalışmalarla bu işlemin tüp bebek tedavileri ile ilgisi belirlenmelidir </div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Steroidler</div>
                                    <div class="divTableCell">HAYIR</div>
                                    <div class="divTableCell">Mevcut verilere göre rutin uygulanmamalıdır</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Rutin dondurulmuş transfer</div>
                                    <div class="divTableCell">HAYIR</div>
                                    <div class="divTableCell">Sadece klinik bir gerekçe varsa önerilmelidir</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Rutin mikroenjeksiyon</div>
                                    <div class="divTableCell">HAYIR</div>
                                    <div class="divTableCell">Sadece  belirgin sperm sorunu varsa tercih edilmelidir</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Erkekte veya kadında antioksidan tedavi</div>
                                    <div class="divTableCell">HAYIR</div>
                                    <div class="divTableCell">Yeterince güvenilir ve iyi kalitede kanıt yok</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Tamamlayıcı ve alternatif tıp</div>
                                    <div class="divTableCell">HAYIR</div>
                                    <div class="divTableCell">Akupunktur verileri tartışmalı, diğerlerinde veri yok</div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            }}
        </LocaleContext.Consumer>

    );
}
