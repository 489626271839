import styled from "styled-components";

const S = {
    Wrapper: styled.section`
    // display: grid;
    width: 100%;
    // grid-template-columns: repeat(2, 1fr);
    // grid-template-rows: auto auto;
    // grid-template-areas:"headerleft headerright"
    // "content content";
    // @media (max-width:648px){
    //     grid-template-columns: 1fr;
    // }
    `,
    Top: styled.div`
    grid-area: headerleft;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding:40px 40px;
    align-items: left;
    background-color: #EAEAF6;
    @media (max-width:648px){
        padding:20px;
    }
    `,
    Header: styled.h1`
    color:black;
    font-size: 20px;
    // background-color: #d41031;
    margin:0;
    /* padding:40px 40px 10px 40px; */
    @media (max-width:648px){
        font-size: 16px;
    }
    `,
    Span: styled.div`
    color:black;
    font-size: 14px;
    // background-color: #d41031;
    margin:0;
    text-transform: capitalize;
    a{
        color:black;
        text-decoration: none;
    }
    /* padding: 10px 40px 40px 40px; */
    `,
    Sertifika: styled.div`
    grid-area: headerright;
    // background-color: #d41031;
    display: flex;
    flex-direction: row;
    justify-content: right;
    padding:0px 40px;
    align-items: center;
    @media (max-width:648px){
        padding:20px;
    }
    svg,img{
        margin:5px;
        width:50px;
        height: 70px;
        filter: invert(100%);
        @media (max-width:648px){
            width:30px;
        height: 30px;
    }
    }
    `,
    Filter:styled.div`
    margin: 40px;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    .filterwrap{
        padding:10px;

    }

    `,
    Section: styled.section`
    margin: 40px;
    grid-area: content;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 40px;
    align-content: center;
    align-items: center;
    @media (max-width:648px){
        grid-template-columns: 1fr;
        margin:20px;
    }
    a{
        box-sizing: border-box;
        overflow:hidden;
        border-radius: 15px;
        height:200px;
        text-decoration:none;
        color:white;
        box-shadow: 9px 15px 22px 5px rgba(0,0,0,0.16);
        // border: 4px solid rgba(1,174,203,0); 
        &:hover{
                transition: 200ms;
                -webkit-box-shadow: 9px 15px 22px 5px rgba(1,174,203,0.16); 
                box-shadow: 9px 15px 22px 5px rgba(1,174,203,0.16);
                border: 4px solid rgb(1,174,203);
            }
        .item-wrapper{
            position:relative;
            width: 100%;
            height:100%;
            display: flex;
            box-sizing:border-box;
            flex-direction: column;
           
            transition: 200ms;
            overflow:hidden;
            .item-image{
                // width: 50%;
                // padding-right: 40px;
                box-sizing:border-box;
                height:100%;
                position:absolute;
                top:0px;
                left:0px;
                z-index:0;
                width:100%;
                img{
                    width: 100%;
                    object-fit: cover;
                    height: 100%;
                   
                }
                // @media (max-width:648px){
                //   height: 100px;
                // }
            }
            article{
                flex: 1;
                display: flex;
                flex-direction: row;
                align-content:space-between;
                justify-content: space-between;
                align-items: flex-end;
                z-index:1;
                background: rgb(0,0,0);
                padding:20px;
                background: rgb(0,0,0);
                background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(254,254,254,0) 50%);
                -webkit-transition: background 1000ms linear;
                -ms-transition: background 1000ms linear;
                transition: background 1000ms linear;
                h2{
                    text-align:left;
                    margin: 0;
                    font-size:1.5em;
                    font-weight:600;
                }
                &:hover{
                    // color:black;
                    -webkit-transition: background 1000ms linear;
                    -ms-transition: background 1000ms linear;
                    transition: background 1000ms linear;
                    background: rgb(1,174,203);
                    background: linear-gradient(0deg, rgba(1,174,203,1) 0%, rgba(254,254,254,0) 100%);
                    h2{
                        font-size:2em;
                    }
                }
            }
            // @media (max-width:648px){
            //     padding:20px;
            //     /* height: auto; */
            //     flex-direction: column;
            // }
        }
    }
    
    `,
}

export default S