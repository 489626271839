import styled from "styled-components";
import Logo from '../../assets/logo.svg'
const S = {
    Wrapper: styled.div`
    z-index: 9;
    // background-color: #00A9C6;
    padding:80px;
    position:relative;
    display:flex;
    flex-direction:column;
    align-items:center;
    @media (max-width:648px){
        padding: 30px 10px;
    }
    h4{
        flex-basis:100%;
        font-size:2em;
        color:black;
        margin:0;
        padding:0;
        font-weight:400;
        text-align:center;
    }
    p{
        color:black;
        text-align:center;
        font-weight:300;
    }
    `,
    ImageWrapper: styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    flex-basis:50%;
    @media (max-width:648px){
        flex-direction:column;
    }
    .contain{
        padding:30px;
        background:white;
        border-radius:16px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        margin:10px;
        display:flex;
        flex-direction:column;
        text-align:center;
        flex-basis:20%;
        align-items:center;
        transition:500ms;
        img{
            width:100px;
        }
        h3,p{
            margin:10px 0;
            padding:0;
        }
        &:hover{
            transition:500ms;
            margin:5px;
            box-shadow: rgba(89,189,215, 0.4) 0px 8px 24px;
            
        }
    }

    `
}

export default S