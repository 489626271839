import React from "react";
import S from './style';

import Egitim from '../../assets/egitimedestek.png';

import TranslateStatic from '../../locales/TranslateStatic'
import { Link } from "react-router-dom";
import MiniSlider from './miniSlider'
export default function () {
    return <S.Wrapper>
        <S.Sol>
            <Link to="/support">
                <p>
                    <b>
                        <TranslateStatic string="EgitimDestekLow1" />
                    </b>
                    <TranslateStatic string="EgitimDestekLow2" />
                </p>
                <article>
                <TranslateStatic string="EgitimArticle1" />
                </article>
                <br/>
                <article>
                <TranslateStatic string="EgitimArticle2" />
                </article>
                <button><TranslateStatic string="Ayrintilar" /> > </button>
            </Link>

        </S.Sol>
        <S.Sag>
            <MiniSlider/>
            {/* <img src={Egitim}/> */}
        </S.Sag>
    </S.Wrapper>
}