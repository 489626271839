
import { Link } from 'react-router-dom';
import S from './style'
import TranslateStatic from '../../locales/TranslateStatic';
import YumurtlamaImg from '../../assets/dondurarak.jpeg'
export function TedaviSureci() {
  return (
    <S.Wrapper>
      <S.Top>
        {/* <S.Header>
          <TranslateStatic string="aboutUsH2" />
        </S.Header> */}
        <S.Span>
          <Link to="./"><TranslateStatic string="home" /></Link> > <Link><TranslateStatic string="TedaviSureci" /></Link>
        </S.Span>
      </S.Top>
      {/* <S.Sertifika>
        <Certification />
      </S.Sertifika> */}
      <S.Section>
        <div className='banner'>
          <div className='baslik'>
            <h1>
              <TranslateStatic string="TedaviSureci" />
            </h1>
          </div>
          <div className='imgwrap'>

            <img className='foregroundImg' src={YumurtlamaImg} />
            <img className="backgroundImg" src={YumurtlamaImg} />
          </div>



        </div>


        <h3><TranslateStatic string="TedaviSureciH3-1" /></h3>
        <p><TranslateStatic string="TedaviSureciP-1" /></p>
        <p><TranslateStatic string="TedaviSureciP-1-2" /></p>
        <p><TranslateStatic string="TedaviSureciP-1-3" /></p>
        <h3><TranslateStatic string="TedaviSureciH3-2" /></h3>
        <p><TranslateStatic string="TedaviSureciP-2" /></p>
        <p><TranslateStatic string="TedaviSureciP-2-1" /></p>
        <h3><TranslateStatic string="TedaviSureciH3-3" /></h3>
        <p><TranslateStatic string="TedaviSureciP-3" /></p>
        <p><TranslateStatic string="TedaviSureciP-3-1" /></p>
        <p><TranslateStatic string="TedaviSureciP-3-2" /></p>
      </S.Section>
    </S.Wrapper >
  );
}
