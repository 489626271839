
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import S from './style'
import axios from "axios";
import PopupC from '../../components/footer/popup';
import TranslateStatic from '../../locales/TranslateStatic';
import { LocaleContext } from '../../locale-context';
export function Iletisim() {
  // const URL = "http://localhost:3000/api"
  const URL = "https://app.gelecektupbebek.com.tr/api"
  const [name, setName] = useState("")
  const [age, setAge] = useState("")
  const [page, setPage] = useState("")
  const [doi, setDoi] = useState("")
  const [pft, setPft] = useState("")
  const [surname, setSurName] = useState("")
  const [email, setEmail] = useState("")
  const [content, setContent] = useState("")
  const [phone, setphone] = useState("")
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const closeModal = () => setOpen(false);
  const closeModalError = () => setOpenError(false);
  function Gonder() {
    // axios.post('http://localhost:3000/api/test')
    const article = {
      name: name,
      surname:surname,
      to:email,
      phone: phone,
      message:content
    };
    axios.post(URL + '/normal', article)
      .then(response => {
        setOpen(o => !o)
        console.log(response)

      }

      ).catch((err) => {
        openError(o => !o)
        console.log(err)
      });;


  }
  function GonderEN() {
    // axios.post('http://localhost:3000/api/test')
    const article = {
      name: name,
      surname:surname,
      age:age,
      page:page,
      doi:doi,
      pft:pft,
      to:email,
      phone: phone,
      message:content
    };
    axios.post(URL + '/normalEn', article)
      .then(response => {
        setOpen(o => !o)
        console.log(response)

      }

      ).catch((err) => {
        openError(o => !o)
        console.log(err)
      });;


  }
  return (
    <S.Wrapper>
      <PopupC open={open} closeModal={closeModal} openError={openError} closeModalError={closeModalError} />
      <S.Top>
        <S.Header>
          <TranslateStatic string="contactHeader" />
        </S.Header>
        <S.Span>
          <Link to="./"><TranslateStatic string="home" /></Link> > <TranslateStatic string="contactHeader" />
        </S.Span>
      </S.Top>
      {/* <S.Sertifika>
        <Certification />
      </S.Sertifika> */}
      <S.Section>
        <h2><TranslateStatic string="contactFormHeader" /></h2>
        <LocaleContext.Consumer>
          {(value) => {
            if (value === "en") {
              return <div className='formEn'>
                <input value={name} onChange={(e)=>setName(e.target.value)} placeholder="Name" type="text" name="isim" />
                <input value={surname} onChange={(e)=>setSurName(e.target.value)} placeholder="Surname" type="text" name="soyisim" />
                <input value={age} onChange={(e)=>setAge(e.target.value)} placeholder="Age" type="text" name="age" />
                <input value={page} onChange={(e)=>setPage(e.target.value)} placeholder="Partner's Age" type="text" name="partnerage" />
                <input value={doi} onChange={(e)=>setDoi(e.target.value)} placeholder="Duration of infertility:" type="text" name="doi" />
                <input value={pft} onChange={(e)=>setPft(e.target.value)} placeholder="Previous fertility treatment (if yes please specify ):" type="text" name="pft" />
                <input value={email} onChange={(e)=>setEmail(e.target.value)} placeholder="E-Mail" type="email" name="email" />
                <input value={phone} onChange={(e)=>setphone(e.target.value)}placeholder="Phone" type="phone" name="phone" />
                <textarea value={content} onChange={(e)=>setContent(e.target.value)} placeholder="Other issues you wish to specify:" name="description" />
                <button onClick={GonderEN}><TranslateStatic string="footerGonder" /></button>
              </div>
            } else if (value === "tr") {
              return <div className='form'>
                <input value={name} onChange={(e)=>setName(e.target.value)} placeholder="Name" type="text" name="isim" />
                <input value={surname} onChange={(e)=>setSurName(e.target.value)} placeholder="Surname" type="text" name="soyisim" />
                <input value={email} onChange={(e)=>setEmail(e.target.value)} placeholder="E-Mail" type="email" name="email" />
                <input value={phone} onChange={(e)=>setphone(e.target.value)}placeholder="Phone" type="phone" name="phone" />
                <textarea value={content} onChange={(e)=>setContent(e.target.value)} placeholder="Description" name="description" />
                <button onClick={Gonder}><TranslateStatic string="footerGonder" /></button>
              </div>
            } else if (value === "ru") {
              return <div className='form'>
                <input value={name} onChange={(e)=>setName(e.target.value)} placeholder="Name" type="text" name="isim" />
                <input value={surname} onChange={(e)=>setSurName(e.target.value)} placeholder="Surname" type="text" name="soyisim" />
                <input value={email} onChange={(e)=>setEmail(e.target.value)} placeholder="E-Mail" type="email" name="email" />
                <input value={phone} onChange={(e)=>setphone(e.target.value)}placeholder="Phone" type="phone" name="phone" />
                <textarea value={content} onChange={(e)=>setContent(e.target.value)} placeholder="Description" name="description" />
                <button onClick={Gonder}><TranslateStatic string="footerGonder" /></button>
              </div>
            }


          }}
        </LocaleContext.Consumer>

      </S.Section>
    </S.Wrapper>
  );
}
